import { Modal, Button, Icon } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FormProvider, useForm } from 'react-hook-form';
import ExportFields from '../material/ExportFields';
import { VisibleModuleType, gasInitialFields, liquidInitialFields, materialInitialFields, solidInitialFields } from '../../utils/materialHelper';
import { useOktaAuth } from '@okta/okta-react';
import { alertCloseAction, alertOpenAction } from '../../redux/actions';
import { useDispatch } from 'react-redux';
import MaterialService from '../../services/materialService';
import { apiRoutes } from '../../utils/apiRoutes';

const initialData = {
    solid: solidInitialFields,
    material: materialInitialFields,
    liquid: liquidInitialFields,
    gas: gasInitialFields
}

const ExportFieldsModal = (props: any) => {
    //auth
    // const { authState } = useOktaAuth();
    // const auth: any = authState ? authState?.accessToken : '';
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useDispatch();
    const methods = useForm({ defaultValues: initialData });

    const exportList = async () => {
        setLoading(true)
        let fields = checkSelected();

        const payload = props.selectAllChecked ? {
            ...fields,
            filters: props?.filters ?? 'All'
        } : {
            ...fields,
            ids: props.selectedRows
        }
        const res = await MaterialService.create(apiRoutes.EXPORT_MATERIAL, payload);
        
        setLoading(false);
        methods.reset({});
        if (res?.data?.code === 200) {
            window.open(res?.data?.body)
            dispatch(alertOpenAction('Data exported successfully.', 'success'));
            setTimeout(() => dispatch(alertCloseAction()));
            props.setOpen(false); // make change

        } else {
            dispatch(alertOpenAction('Oops ! something went wrong.', 'error'));
            setTimeout(() => dispatch(alertCloseAction()));
        }
    }

    const checkSelected = () => {
        let materialArr: any = [];
        let gasArr: any = [];
        let solidArr: any = {};
        let liquidArr: any = [];

        solidArr = methods?.control?._formValues?.solid ?
            Object.keys(methods?.control?._formValues?.solid) && Object.keys(methods?.control?._formValues?.solid).map(
                (key: string) => {
                    let o: any = {};
                    o[key] = Object.keys(methods?.control?._formValues?.solid[key]).filter(
                        (item: string) => {
                            if (methods?.control?._formValues?.solid[key][item] == true) {
                                return item;
                            }
                        })
                    return o;
                }
            ) : ''

        gasArr = methods?.control?._formValues?.gas ?
            Object.keys(methods?.control?._formValues?.gas) && Object.keys(methods?.control?._formValues?.gas).map(
                (key: string) => {
                    let o: any ={};
                    o[key] = Object.keys(methods?.control?._formValues?.gas[key]).filter(
                        (item: string) => {
                            if(methods?.control?._formValues?.gas[key][item] == true) {
                                return item;
                            }
                        })
                        return o;
                }
            ) : ''

        materialArr = methods?.control?._formValues?.material ?
            Object.keys(methods?.control?._formValues?.material) && Object.keys(methods?.control?._formValues?.material).filter(
                (key: string) => {
                    if (methods?.control?._formValues?.material[key] == true) {
                        return key
                    }
                }
            ) : ''

            liquidArr = methods?.control?._formValues?.liquid ?
            Object.keys(methods?.control?._formValues?.liquid) && Object.keys(methods?.control?._formValues?.liquid).map(
                (key: string) => {
                    let o: any ={};
                    o[key] = Object.keys(methods?.control?._formValues?.liquid[key]).filter(
                        (item: string) => {
                            if(methods?.control?._formValues?.liquid[key][item] == true) {
                                return item;
                            }
                        })
                        return o;
                }
            ) : ''

        return {
            fields: materialArr && materialArr?.filter((item: any) => item !== 'selectAll'),
            liquid: liquidArr && liquidArr?.filter((item: any) => item !== 'selectAll') ,
            gas: gasArr && gasArr?.filter((item: any) => item !== 'selectAll'),
            solid: solidArr && solidArr?.filter((item: any) => item !== 'selectAll'),
        }
    }

    useEffect(() => {
        methods.watch();
    })

    return (
        <>
            <Modal
                open={props.open}
                size='large'
                dimmer='blurring'
                onClose={() => { methods.reset({}), props.setOpen(false) }}
            >
                <FormProvider {...methods}>
                    <form>
                        <Modal.Header align="center" className='border-none pb-0'>
                            <Icon name="times circle outline" onClick={() => { methods.reset({}), props.setOpen(false) }} color="grey" className='close-icon '
                            ></Icon>
                        </Modal.Header>
                        <Modal.Content scrolling>
                            <Modal.Description>

                                <Tabs>
                                    <div className='sticky-tab-list'>
                                        <p className='head-sm pt-3 text-left pl-4 pb-0 mb-0 pb-1'>Please select the fields you want to export !</p>
                                        <div className="admin-tabs mb-6">
                                            <TabList className="inner mb-3 flex-wrap">
                                                <Tab>
                                                    <div className={`tab-link`}>Material</div>
                                                </Tab>
                                                <Tab>
                                                    <div className={`tab-link`}>Solid</div>
                                                </Tab>
                                                <Tab>
                                                    <div className={`tab-link`}>Liquid</div>
                                                </Tab>
                                                <Tab >
                                                    <div className={`tab-link`}>Gas</div>
                                                </Tab>
                                            </TabList>
                                        </div>
                                    </div>
                                    <TabPanel>
                                        <ExportFields type="material" isAttachmentsRequired={false} moduleType={VisibleModuleType.EXPORT} />
                                    </TabPanel>
                                    <TabPanel>
                                        <ExportFields type="solid" isAttachmentsRequired={false} moduleType={VisibleModuleType.EXPORT} />
                                    </TabPanel>
                                    <TabPanel>
                                        <ExportFields type="liquid" isAttachmentsRequired={false} moduleType={VisibleModuleType.EXPORT}/>
                                    </TabPanel>
                                    <TabPanel>
                                        <ExportFields type="gas" isAttachmentsRequired={false} moduleType={VisibleModuleType.EXPORT} />
                                    </TabPanel>
                                </Tabs>
                            </Modal.Description>
                        </Modal.Content>
                        <Modal.Actions className='border-none text-center bg-none mb-6 pt-3'>
                            <Button title="Export" color='red' type='submit' disabled={loading ? true : false} onClick={(event) =>{event.preventDefault();
                                 exportList()}}>
                                {loading ?
                                    <>
                                        <i className="fa-solid fa-circle-notch fa-spin" data-testid="loading-spinner"></i> Export
                                        <div className="theme-loader">
                                            <div className="loader-outer">
                                                <div className="loader"></div>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        Export
                                    </>
                                }
                            </Button>
                            <Button title="Cancel" type='button' onClick={() => { methods.reset({}), props.setOpen(false) }}  aria-label="Close">
                                Cancel
                            </Button>
                        </Modal.Actions>
                    </form>
                </FormProvider>
            </Modal>
        </>
    )
};
export default ExportFieldsModal;