import { Modal, Button, Icon } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';

interface VisualizationUnitWarningModalProps {
    open: boolean;
    setOpen: (val: boolean) => void;
    preceed : () => void;
    noData?: boolean;
}

const VisualizationUnitWarningModal = ({open,setOpen,preceed,noData}:VisualizationUnitWarningModalProps) => {
    return (
        <Modal
            open={open}
            size="tiny"
            dimmer='blurring'
            onClose={() => setOpen(false)}
        >
            <Modal.Header align="center" className='border-none pb-0'>
                <div className='mt-8'>
                    <Icon name='chart bar' color="red" size="big" />
                </div>
                <Icon name="times circle outline" onClick={() => setOpen(false)} color="grey" className='close-icon'></Icon>
            </Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <p className='head-lg text-center pb-0'>{ noData ? "No data to display" : "The selected materials have varying units. Would you like to proceed with generating the chart?"}</p>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions className='border-none text-center bg-none mb-6'>
                {
                    !noData &&
                    <Button title="Proceed" color='red' onClick={() =>{preceed()}}>
                        Proceed
                    </Button>
                    }
                <Button title="Cancel" onClick={() => { setOpen(false) }}>
                    { noData ? "Close" : "Cancel" }
                </Button>
            </Modal.Actions>
        </Modal>
    )
};
export default VisualizationUnitWarningModal;