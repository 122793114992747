import React from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security, LoginCallback } from "@okta/okta-react";

// modals
import CorsErrorModal from "./components/modals/CorsErrorModal";
import AuthRequiredModal from "./components/modals/AuthRequiredModal";

// pages
import Home from "./pages";
import Profile from "./pages/auth/Profile";
import MaterialIndex from "./pages/materials";
import MaterialList from "./pages/materials/List";
import MaterialNew from "./pages/materials/New";
import MaterialSolidPhase from "./pages/materials/phase/Solid";
import MaterialGasPhase from "./pages/materials/phase/Gas";
import MaterialFind from "./pages/materials/Find";
import Solubility from "./pages/materials/Solubility";
import MaterialUpdate from "./pages/materials/Update";
import MaterialLiquidPhase from "./pages/materials/phase/Liquid";
import SpectralInfo from "./pages/materials/SpectralInformation";
import Environmental from "./pages/materials/Environmental";
import MaterialSolubility from "./pages/materials/material-solubility";
import MaterialView from "./pages/materials/View";
import EquipmentIndex from "./pages/equipments";
import EquipmentScreening from "./pages/equipments/Screening";
import EquipmentModel from "./pages/equipments/Models";
import EquipmentFind from "./pages/equipments/Find";
import EquipmentNew from "./pages/equipments/New";
import UserNew from "./pages/users/new";
import UserList from "./pages/users/list";
import EquipmentUpdate from "./pages/equipments/Update";
import UserForm from "./pages/users/user-form";
import NotFoundPage from "./pages/404";
import PermissionDenied from "./pages/permission-denied";
import CustomLoginComponent from "./pages/auth/Login";
import SecureRoute from "./components/SecureRoute";
import InternalProcessesIndex from "./pages/apps-and-models";
import Crystallization from "./pages/apps-and-models/Crystallization";

// configg
import config from "./utils/config";
import MaterialVisualize from "./pages/materials/visualize";

const oktaAuth = new OktaAuth(config.oidc);

function App() {
  const history = useHistory(); // example from react-router

  React.useEffect(() => {
    document.addEventListener("keydown", (e: any) => {
      // Check Scientific Notation if the pressed key is 'e' or 'E'
      if (e.target.type === "number" && e.key.toLowerCase() === "e") {
        // Prevent the default behavior for 'e' and 'E'
        e.preventDefault();
      }
    });
  });

  // state
  const [corsErrorModalOpen, setCorsErrorModalOpen] = React.useState(false);
  const [authRequiredModalOpen, setAuthRequiredModalOpen] = React.useState(false);

  const triggerLogin = async () => {
    await oktaAuth.signInWithRedirect();
  };

  const restoreOriginalUri = async ({ _oktaAuth, originalUri }: any) => {
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  const customAuthHandler = async () => {
    const previousAuthState = oktaAuth.authStateManager.getPreviousAuthState();
    if (!previousAuthState || !previousAuthState.isAuthenticated) {
      // App initialization stage
      triggerLogin();
    } else {
      // Ask the user to trigger the login process during token autoRenew process
      setAuthRequiredModalOpen(true);
    }
  };

  const onAuthResume = async () => {
    history.push("/login");
  };

  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={customAuthHandler}
      restoreOriginalUri={restoreOriginalUri}
    >
      {/* <Navbar {...{ setCorsErrorModalOpen }} /> */}
      <CorsErrorModal {...{ corsErrorModalOpen, setCorsErrorModalOpen }} />
      <AuthRequiredModal {...{ authRequiredModalOpen, setAuthRequiredModalOpen, triggerLogin }} />
      <div>
        <Switch>
          <Route path="/login/callback" render={(props) => <LoginCallback {...props} onAuthResume={onAuthResume} />} />
          <Route path="/login" render={() => <CustomLoginComponent {...{ setCorsErrorModalOpen }} />} />
          <Route path="/permission-denied" component={PermissionDenied} />

          <SecureRoute path="/" exact readPermssion={true} component={Home} />
          <SecureRoute path="/profile" exact={true} readPermssion={true} component={Profile} />
          <SecureRoute path="/apps-and-models" exact={true} readPermssion={true}
                       component={InternalProcessesIndex} />
          <SecureRoute path="/apps-and-models/crystallization" exact={true} readPermssion={true}
                       component={Crystallization} />
          <SecureRoute path="/materials" exact={true} component={MaterialIndex} requiredPermission="read" />
          <SecureRoute path="/materials/list" exact={true} component={MaterialList} requiredPermission="read" />
          <SecureRoute path="/materials/visualize" exact={true} component={MaterialVisualize} requiredPermission="read" />
          <SecureRoute path="/materials/material-solubility" exact={true} component={MaterialSolubility}
                       requiredPermission="read" />
          <SecureRoute path="/materials/new" exact={true} component={MaterialNew} requiredPermission="write" />
          <SecureRoute path="/materials/find" exact={true} component={MaterialFind} requiredPermission="read" />
          <SecureRoute path="/materials/:id/view" exact={true} component={MaterialView} requiredPermission="read" />
          <SecureRoute path="/materials/view" exact={true} component={MaterialView} requiredPermission="read" />
          <SecureRoute path="/materials/solubility" exact={true} component={Solubility} requiredPermission="read" />
          <SecureRoute path="/materials/:id/solubility" exact={true} component={Solubility} requiredPermission="read" />
          <SecureRoute path="/materials/spectral-information" exact={true} component={SpectralInfo}
                       requiredPermission="read" />
          <SecureRoute path="/materials/:id/spectral-information" exact={true} component={SpectralInfo}
                       requiredPermission="read" />
          <SecureRoute path="/materials/environmental-data" exact={true} component={Environmental}
                       requiredPermission="read" />
          <SecureRoute path="/materials/:id/environmental-data" exact={true} component={Environmental}
                       requiredPermission="read" />
          <SecureRoute path="/materials/:id/update" exact={true} component={MaterialUpdate}
                       requiredPermission="write" />
          <SecureRoute path="/materials/phase/gas" exact={true} component={MaterialGasPhase}
                       requiredPermission="read" />
          <SecureRoute path="/materials/phase/solid" exact={true} component={MaterialSolidPhase}
                       requiredPermission="read" />
          <SecureRoute path="/materials/phase/liquid" exact={true} component={MaterialLiquidPhase}
                       requiredPermission="read" />
          <SecureRoute path="/materials/:id/phase/gas" exact={true} component={MaterialGasPhase}
                       requiredPermission="read" />
          <SecureRoute path="/materials/:id/phase/solid" exact={true} component={MaterialSolidPhase}
                       requiredPermission="read" />
          <SecureRoute path="/materials/:id/phase/liquid" exact={true} component={MaterialLiquidPhase}
                       requiredPermission="read" />
          <SecureRoute path="/equipments" exact={true} component={EquipmentIndex} requiredPermission="read" />
          <SecureRoute path="/equipments/screening" exact={true} component={EquipmentScreening}
                       requiredPermission="read" />
          <SecureRoute path="/equipments/models" exact={true} component={EquipmentModel} requiredPermission="read" />
          <SecureRoute path="/equipments/find" exact={true} component={EquipmentFind} requiredPermission="read" />
          <SecureRoute path="/equipments/:id/view" exact={true} component={EquipmentFind} requiredPermission="read" />
          <SecureRoute path="/equipments/new" exact={true} component={EquipmentNew} requiredPermission="write" />
          <SecureRoute path="/equipments/:id/update" exact={true} component={EquipmentUpdate}
                       requiredPermission="write" />
          <SecureRoute path="/users/list" exact={true} component={UserList} />
          <SecureRoute path="/users/new" exact={true} component={UserNew} />
          <SecureRoute path="/users/user-form" exact={true} component={UserForm} />
          <SecureRoute path="*" component={NotFoundPage} />
        </Switch>
      </div>
    </Security>
  );
}

export default App;
