import { yupResolver } from "@hookform/resolvers/yup";
import { useOktaAuth } from "@okta/okta-react";
import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as yup from "yup";

// components
import { ButtonIcons, ButtonProps, ButtonTypes } from "../../components/common/Button";
import { TabType } from "../../components/common/Tabs";
import Layout from "../../components/layout";
import BodyHeader from "../../components/layout/BodyHeader";
import CancelConfirmModal from "../../components/modals/CancelFormModal";
import MutateMaterialForm, { FormInputDetails } from "../../components/material/MutateMaterialForm";

// modals
import HelpModal from "../../components/modals/HelpModal";

// context
import { useUser } from "../../components/context/UserContext";

// redux actions
import { alertCloseAction, alertOpenAction } from "../../redux/actions";
import { materialAction, materialsAction } from "../../redux/actions/materialActions";

// props
import { RootState } from "../../redux/store";
import { FilesProps, MaterialData } from "../../types";
import { VendorProps } from "../../types/materialsTypes";

// helpers
import FileService from "../../services/fileService";
import MaterialService from "../../services/materialService";
import { UPLOAD_FILE_MAXLENGTH } from "../../utils";
import { apiRoutes } from "../../utils/apiRoutes";
import { findFile, getUpdatedFiles, setTitleNumberInput, sortItemsByKey } from "../../utils/common";
import {ERROR_MESSAGE,ADD_MATERIAL_PAGE_TITLE, layoutTitles } from "../../utils/constant";
import { MaterialHelp } from "../../utils/helpContent";
import { attachmentsTypes, checkDuplicateFiles, chemicalSafetyOptions, materialPhaseTypes, regulatoryFilingOptions, vendorNames } from "../../utils/materialHelper";
import ImportModal from "../../components/modals/ImportModal";

const schema = yup.object({
  material_name: yup.string().required("Please enter material name."),
  vendor_name: yup.string().required("Please enter vendor name."),
  phase: yup.string().when("page", {
    is: "add",
    then: (schema) => schema.required("Please enter phase."),
    otherwise: (schema) => schema, // technically this otherwise isnt needed
  }),
  lot_number: yup.string().required("Please enter lot number."),
});

const MaterialNew = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  // auth
  const { authState } = useOktaAuth();
  const auth: any = authState ? authState?.accessToken : "";

  // user context
  const { user } = useUser();
  const hasWritePermission = user?.permissions?.updated?.material?.hasReadAndWrite ?? false;

  // Redux data
  const material = useSelector((state: RootState) => state?.material);

  //states
  const [isLoading, setIsLoading] = useState(false);
  const [openCancelConfirmModal, setOpenCancelConfirmModal] = useState<boolean>(false);
  const [openHelpModal, setOpenHelpModal] = useState(false);
  const [materialData, setMaterialData] = useState<any>();
  const [isFirstStepCompleted, setIsFirstStepCompleted] = useState<boolean>(false);
  const [vendors, setVendors] = useState<VendorProps[]>(vendorNames);
  const [selectedAttachmentType, setSelectedAttachmentType] = useState<string>(attachmentsTypes[0].value);
  const [attachments, setAttachments] = useState<FilesProps[]>([]);
  const [documents, setDocuments] = useState<FilesProps[]>([]);
  const [openImportModal, setOpenImportModal] = useState<boolean>(false);

  // form
  const initialData: MaterialData = {
    page: material?.item ? "update" : "add",
    material_name: materialData?.material_name ?? "",
    vendor_name: materialData?.vendor_name ?? "",
    phase: materialData?.phase ?? "",
    lot_number: materialData?.lot_number ?? "0000",
    abbreviation: materialData?.abbreviation ?? "",
    brand_name: materialData?.brand_name ?? "",
    synonyms: materialData?.synonyms ?? "",
    boiling_point: materialData?.boiling_point ?? "",
    cas_number: materialData?.cas_number ?? "",
    chemical_formula: materialData?.chemical_formula ?? "",
    incompatibility: materialData?.incompatibility ?? "",
    shelf_life: materialData?.shelf_life ?? "",
    melting_point: materialData?.melting_point ?? "",
    price: materialData?.price ?? "",
    molecular_weight: materialData?.molecular_weight ?? "",
    purity: materialData?.purity ?? "",
    smiles: materialData?.smiles ?? "",
    manufacturing_number: materialData?.manufacturing_number ?? "",
    project_name: materialData?.project_name ?? "",
    process_name: materialData?.process_name ?? "",
    chemical_safety: materialData?.chemical_safety ?? "",
    regulatory_filing_data: materialData?.regulatory_filing_data ?? "",
  };

  const { control, handleSubmit, formState: { errors }, formState, reset } = useForm<MaterialData>({
    resolver: yupResolver(schema), defaultValues: initialData,
  });

  const refreshData = (e: any) => {
    e.preventDefault();
    e.returnValue = "";
  };

  const uploadFile = async (file: any) => {
    let newAttachment = await findFile(attachments, file);
    if (!newAttachment) {
      newAttachment = await findFile(documents, file);
    }
    if (newAttachment) {
      const blob = new Blob([newAttachment?.file as any], { type: newAttachment?.file?.type });
      const uploadResponse = await fetch(file.signedUrl,
        {
          method: "PUT",
          body: blob,
        });
      if (uploadResponse.ok) return true;
      return false;
    }
    return false;
  };

  const uploadFileAPI = async (data: any, attachmentList: any[], id?: string) => {
    let result: any = {
      code: 200,
      status: "success",
      message: "",
      data: {},
    };
    if (attachmentList.length) { // attachments list
      // file upload request
      const filesResponse = await FileService.create("/files/upload", {
        id: material?.item?.id ?? id,
        file_for: "Material",
        files: attachmentList,
      });

      if (filesResponse?.status === 200) {
        const filesResult = filesResponse?.data;
        return Promise.all(filesResult.body.files.map(async (file: any) => await uploadFile(file)))
          .then(async (res) => {
            // update material object
            filesResult.body.files.map((file: any) => delete file.signedUrl);

            if (material?.item?.attachments?.length) {
              filesResult.body.files = [...material?.item?.attachments, ...filesResult.body.files];
            }

            filesResult.body.files = await getUpdatedFiles(filesResult?.body?.files ?? [], [...attachments, ...documents], !0);

            const materialResponse = await MaterialService.update({
              ...data,
              id: material?.item?.id ?? id,
              uid: `${auth?.claims?.uid}`,
              attachments: filesResult.body.files,
            });

            if (materialResponse?.status === 200) {
              dispatch(materialAction(materialResponse?.data?.body));
              setIsFirstStepCompleted(true);
              result.data = materialResponse?.data?.body;
              result.message = "Material updated successfully.";
              return result;
            }
            result.code = 400;
            result.status = "error";
            result.message = "Oops! something went wrong.";
            return result;
          })
          .catch(err => {
            result.code = 400;
            result.status = "error";
            result.message = "Oops! something went wrong.";
            return result;
          });
      }
      result.code = 400;
      result.status = "error";
      result.message = "Oops! something went wrong.";
      return result;
    }
    return result;
  };

  const apiRequest = async (data: any) => {
    let result: any = {
      code: 200,
      status: "success",
      message: "",
      data: {},
    };
    // attachments list
    let attachmentList = [...attachments, ...documents].filter((attachment: any) => !attachment.isUpload).map(
      (attachment) => ({
        category: attachment?.category,
        key: attachment.file?.name,
        mimeType: attachment?.file?.type,
      }));
    // if form already submitted : update data else add data
    if (material?.item) {
      if (attachmentList.length) {
        // upload files API
        return { ...result, ...await uploadFileAPI(data, attachmentList) };
      } else {
        const payload = {
          ...data,
          id: material?.item?.id,
          uid: `${auth?.claims?.uid}`,
          attachments: material?.item?.attachments?.reduce((e: any[], t: any) => {
            let newItem = attachments.find(
              (attachment: any) => (attachment.category === t.category && attachment?.file?.name === t.display_name));
            if (!newItem) {
              newItem = documents.find((document: any) => (document.category === t.category && document?.file?.name.toLocaleLowerCase().replaceAll(" ", "-") === t.display_name));
            }
            if (newItem) {
              e = [...e, t];
            }
            return e;
          }, []),
        };

        const materialResponse = await MaterialService.update(payload);
        if (materialResponse?.status === 200) {
          dispatch(materialAction(materialResponse?.data?.body));
          setIsFirstStepCompleted(true);
          result.data = materialResponse?.data?.body;
          result.message = "Material updated successfully.";
          return result;
        }
        if (materialResponse?.status == 409) {
          result.code = materialResponse?.data?.code;
          result.status = "error";
          result.message = materialResponse?.data?.message;
          return result;
        }
        result.code = 400;
        result.status = "error";
        result.message = "Oops! something went wrong.";
        return result;
      }
    } else {
      const payload = {
        ...data,
        uid: `${auth?.claims?.uid}`,
      };

      const res = await MaterialService.create(apiRoutes.MATERIALS, payload);
      if (res?.status === 200) {
        if (res?.data?.code == 409) {
          result.code = res?.data?.code;
          result.status = "error";
          result.message = res?.data?.message;
          return result;
        } else if (res?.data?.code == 200) {
          dispatch(materialAction(res?.data?.body));
          if (attachmentList.length) {
            return {
              ...result,
              ...await uploadFileAPI(data, attachmentList, res?.data?.body?.id),
              ...{ message: "Material added successfully." },
            };
          }
          result.data = res?.data?.body;
          result.message = "Material added successfully.";
          return result;
        }
        result.code = 400;
        result.status = "error";
        result.message = "Oops! something went wrong.";
        return result;
      }
      result.code = 400;
      result.status = "error";
      result.message = "Oops! something went wrong.";
      return result;
    }
  };

  const validateForm = async () => {
    // find invalid files
    const invalidateFiles = [...attachments, ...documents].find((attachment: any) => attachment.error);
    if (invalidateFiles) { // Check invalid file
      dispatch(alertOpenAction("Max file size exceed. Please try again with valid files.", "error"));
      setTimeout(() => dispatch(alertCloseAction()));
      return;
    }

    // find duplicate files
    const isDuplicate = await (await checkDuplicateFiles([...attachments, ...documents]))?.map((item: any) => item.isDuplicate).includes(true);
    if (isDuplicate) {
      dispatch(alertOpenAction("Please remove duplicate files.", "error"));
      setTimeout(() => dispatch(alertCloseAction()));
      return;
    }
    return true;
  };

  // Form submit
  const onSubmit: SubmitHandler<any> = async (data: MaterialData) => {
    if (await validateForm()) {
      setIsLoading(true); // enable loading
      const apiResponse = await apiRequest(data);
      setIsLoading(false); // disable loading
      if (apiResponse.code === 200) {
        dispatch(alertOpenAction(apiResponse.message, "success"));
      } else {
        dispatch(alertOpenAction(apiResponse.message, "error"));
      }
      setTimeout(() => dispatch(alertCloseAction()));
    }
  };

  // Tabs 
  const phaseTab = () => {
    if (material.item) {
      if (materialData.phase == "Solid") {
        history.push("/materials/phase/solid");
      } else if (materialData.phase == "Gas") {
        history.push("/materials/phase/gas");
      } else if (materialData.phase == "Liquid") {
        history.push("/materials/phase/liquid");
      }
    } else {
      dispatch(alertOpenAction("Please add material data first.", "error"));
      setTimeout(() => dispatch(alertCloseAction()));
    }
  };

  const solubilityTab = () => {
    if (material.item) {
      history.push("/materials/solubility");
    } else {
      dispatch(alertOpenAction("Please add material data first.", "error"));
      setTimeout(() => dispatch(alertCloseAction()));
    }
  };

  const environmentalDataTab = () => {
    if (material.item) {
      history.push("/materials/environmental-data");
    } else {
      dispatch(alertOpenAction("Please add material data first.", "error"));
      setTimeout(() => dispatch(alertCloseAction()));
    }
  };

  const spectralInfoTab = () => {
    if (material.item) {
      history.push("/materials/spectral-information");
    } else {
      dispatch(alertOpenAction("Please add material data first.", "error"));
      setTimeout(() => dispatch(alertCloseAction()));
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === "processAttachments") {
      let displayNames = material?.item?.attachments ?? [];
      displayNames = displayNames?.map((attachment: any) => attachment.category == "Process Attachment" ? attachment.display_name : "");

      const isDuplicate = Object.keys(e?.target?.files ?? []).find((key: any) => {
        if (displayNames.includes(e?.target?.files?.[key].name)) {
          return true;
        }
      });
      if (isDuplicate) {
        dispatch(alertOpenAction("Same file already uploaded.", "error"));
        setTimeout(() => dispatch(alertCloseAction()));
        return;
      }
      setDocuments([...documents, ...Object.keys(e?.target?.files ?? []).map(
        (key: any) => {
          const fileSize = e?.target?.files?.[key]?.size ?? 0;
          return {
            category: "Process Attachment",
            file: e?.target?.files?.[key],
            error: parseFloat((fileSize / (1024 * 1024)).toFixed(2)) > UPLOAD_FILE_MAXLENGTH ? true : false,
          };
        })]);
      return;
    }

    let displayNames = material?.item?.attachments ?? [];
    displayNames = displayNames?.map((attachment: any) => attachment.category == selectedAttachmentType ? attachment.display_name : "");
    const isDuplicate = Object.keys(e?.target?.files ?? []).find((key: any) => {
      if (displayNames.includes(e?.target?.files?.[key].name)) {
        return true;
      }
    });
    if (isDuplicate) {
      dispatch(alertOpenAction("Same file already uploaded.", "error"));
      setTimeout(() => dispatch(alertCloseAction()));
      return;
    }
    setAttachments([
      ...attachments,
      ...Object.keys(e?.target?.files ?? []).map(
        (key: any) => {
          const fileSize = e?.target?.files?.[key]?.size ?? 0;
          return {
            category: selectedAttachmentType,
            file: e?.target?.files?.[key],
            error: parseFloat((fileSize / (1024 * 1024)).toFixed(2)) > UPLOAD_FILE_MAXLENGTH ? true : false,
          };
        }),
    ]);
  };

  //Remove selected file
  const removeFile = (index: number, name: string) => {
    if (name === "processAttachments") {
      setDocuments(documents.filter((document, i) => i !== index));
      return;
    }
    setAttachments(attachments.filter((attachment, i) => i !== index));
  };

  // Cancel Modal: Save Changes
  const saveMaterial = async () => {
    const { vendor_name, material_name, lot_number } = control._formValues;
    if ((!vendor_name?.length || !material_name?.length || !lot_number?.length)) {
      setOpenCancelConfirmModal(false);
      dispatch(alertOpenAction("Please fill all required fields first.", "error"));
      setTimeout(() => dispatch(alertCloseAction()));
      return;
    }
    if (JSON.stringify(initialData) !== JSON.stringify(control._formValues)) {
      setOpenCancelConfirmModal(false);
      if (await validateForm()) {
        setIsLoading(true); // enable loading
        const apiResponse = await apiRequest({ ...control._formValues });
        setIsLoading(false); // disable loading
        if (apiResponse.code === 200) {
          dispatch(alertOpenAction(apiResponse.message, "success"));
          history.push("/materials");
        } else {
          dispatch(alertOpenAction(apiResponse.message, "error"));
        }
        setTimeout(() => dispatch(alertCloseAction()));
      }
    } else {
      setOpenCancelConfirmModal(false);
      dispatch(alertOpenAction("No data updated to save.", "error"));
      setTimeout(() => dispatch(alertCloseAction()));
    }
  };

  // Cancel Modal: Discard Changes
  const discardChanges = () => history.push("/materials");

  // Handle Close Button click
  const handleClose = (path: string) => {
    if (formState.isDirty || Object.keys(formState.dirtyFields)?.length ||
      (!material?.item && [...attachments, ...documents].length) ||
      (Array.isArray(material?.item?.attachments) && (material?.item?.attachments?.length !== [...attachments, ...documents]?.length))
    ) {
      setOpenCancelConfirmModal(true);
      return;
    }
    history.push(path);
  };

  //handle import button click
  const handleImportModal = () => {
    setOpenImportModal(true);
  };


  const breadCrumbItems = [
    { label: "Home", path: "#", onClick: () => handleClose("/") },
    { label: "Materials", path: "#", onClick: () => handleClose("/materials") },
    { label: "Add Material", path: "#" }
  ];

  // Header Buttons
  const headerButtons: ButtonProps[] = [
    {
      isIconButton: true,
      navigateTo: "#",
      icon: ButtonIcons.IMPORT,
      title: "Import",
      type: ButtonTypes.PRIMARY,
      onClick: handleImportModal,
    },
    ...(hasWritePermission ? [{
      isIconButton: true,
      icon: ButtonIcons.SAVE,
      title: "Save",
      type: ButtonTypes.PRIMARY,
      disabled: !hasWritePermission || isLoading,
      onClick: () => handleSubmit(onSubmit)()
    }] : []),
    {
      isIconButton: true,
      navigateTo: "#",
      icon: ButtonIcons.CROSS,
      title: "Close",
      type: ButtonTypes.SECONDARY,
      onClick: () => handleClose("/materials")
    },
    {
      isIconButton: true,
      navigateTo: "#",
      icon: ButtonIcons.HELP,
      title: "Help",
      type: ButtonTypes.SECONDARY,
      onClick: () => setOpenHelpModal(true)
    }
  ];

  const tabs: TabType[] = [
    {
      title: 'Material',
      isActive: true,
      isCompleted: isFirstStepCompleted
    },
    {
      title: 'Phase',
      dataCy: "phase-tab",
      isCompleted: materialData && (materialData?.gas || materialData?.solid || materialData?.liquid),
      onClick: phaseTab
    },
    {
      title: 'Solubility',
      dataCy: "solubility-tab",
      isCompleted: materialData && materialData?.solubility,
      onClick: solubilityTab
    },
    {
      title: 'Spectral Information',
      dataCy: "spectral-tab",
      isCompleted: materialData && materialData?.spectralInfo,
      onClick: spectralInfoTab
    },
    {
      title: 'Environmental Data',
      dataCy: "enviornmental-tab",
      isCompleted: materialData && materialData?.environment,
      onClick: environmentalDataTab
    }
  ];

  const materialFormOneData: FormInputDetails[] = [
    {
      isRequired: true,
      label: "Material Name",
      key: "material_name",
    },
    {
      isDropdown: true,
      isRequired: true,
      label: "Vendor",
      key: "vendor_name",
      options: vendors,
    },
    {
      isDropdown: true,
      isRequired: true,
      label: "Phase",
      key: "phase",
      options: materialPhaseTypes,
    },
    {
      label: "Abbreviation",
      key: "abbreviation",
    },
    {
      label: "Brand Name",
      key: "brand_name",
    },
    {
      label: "Synonyms",
      key: "synonyms",
    },
    {
      type: "number",
      label: "Boiling Point (C)",
      key: "boiling_point",
    },
    {
      label: "CAS Number",
      key: "cas_number",
    },
    {
      label: "Chemical Formula",
      key: "chemical_formula",
    },
    {
      label: "Incompatibility",
      key: "incompatibility",
    },
    {
      type: "number",
      label: "Decomposition/Shelf-life (Month)",
      key: "shelf_life",
    },
    {
      isDropdown: true,
      label: "Chemical Safety",
      key: "chemical_safety",
      options: chemicalSafetyOptions,
    },
    {
      type: "number",
      label: "Melting Point (C)",
      key: "melting_point",
    },
    {
      type: "number",
      label: "Price ($/g)",
      key: "price",
    },
    {
      type: "number",
      label: "Molecular Weight (g/mol)",
      key: "molecular_weight",
    },
    {
      type: "number",
      label: "Purity (%)",
      key: "purity",
    },
    {
      label: "SMILES",
      key: "smiles",
    }
  ];

  const materialFormTwoData: FormInputDetails[] = [
    {
      isRequired: true,
      label: "Lot Number",
      key: "lot_number",
    },
    {
      label: "Manufacturing Number",
      key: "manufacturing_number",
    },
    {
      label: "Project Name",
      key: "project_name",
    },
    {
      isDropdown: true,
      label: "Regulatory Filing Data",
      key: "regulatory_filing_data",
      options: regulatoryFilingOptions
    },
    {
      label: "Process Name",
      key: "process_name",
    }
  ];
  
  // set data-cy attribute to form elements
  useEffect(() => {
    document.querySelectorAll("input.theme-ip, select.theme-ip").forEach((element) => {
      // @ts-ignore
      if (element && element.name) {
        // @ts-ignore
        element.setAttribute("data-cy", element.name);
      }
    });
    document.querySelectorAll("input[type='file']").forEach(element => {
      // @ts-ignore
      if (element && element.name) {
        // @ts-ignore
        element.setAttribute("data-cy", element.name);
      }
    });
  }, []);

  // To show warning when trying to exit form
  useEffect(() => {
    window.addEventListener("beforeunload", refreshData);
    return () => {
      window.removeEventListener("beforeunload", refreshData);
    };
  }, []);

  // Set vendors for external users
  useEffect(() => {
    if (user) {
      const { userType, vendors } = user;
      if (userType.includes("external")) {
        // okta dashboard vendors for external users.
        setVendors(sortItemsByKey([
          { label: "Generic", value: "Generic" },
          ...vendors.map((vendor: any) => ({ label: vendor, value: vendor })),
        ], "label"));
      }
    }
  }, [user]);

  useEffect(() => {
    // set default title on number input fields.
    setTitleNumberInput();
  }, []);

  useEffect(() => {
    setMaterialData(material.item);
    material.item ? setIsFirstStepCompleted(true) : "";
    reset(material.item);
    if (material?.item?.attachments?.length) {
      let newAttachments: any = [];
      let newDocuments: any = [];
      material?.item?.attachments?.forEach((attachment: any) => {
        let e: any = {};
        let d: any = {};
        if (attachment.category === "Molecular Structure" ||
          attachment.category === "USP Monograph" ||
          attachment.category === "Chemical Safety" ||
          attachment.category === "HPLC Method" ||
          attachment.category === "SMILES" ||
          attachment.category === "Others"
        ) {
          e.category = attachment.category;
          e.error = false;
          e.isUpload = true;
          e.file = {
            name: attachment.display_name,
            type: attachment.mime_type,
          };
          newAttachments.push(e);
        }
        if (attachment.category === "Process Attachment") {
          d.category = attachment.category;
          d.error = false;
          d.isUpload = true;
          d.file = {
            name: attachment.display_name,
            type: attachment.mime_type,
          };
          newDocuments.push(d);
        }
      });
      setAttachments(newAttachments);
      setDocuments(newDocuments);
    }
  }, [material.item]);

  const handleBackClick = () => {
    if (formState.isDirty || Object.keys(formState.dirtyFields)?.length ||
      (!material?.item && [...attachments, ...documents].length) ||
      (Array.isArray(material?.item?.attachments) && (material?.item?.attachments?.length !== [...attachments, ...documents]?.length))
    ) {
      setOpenCancelConfirmModal(true);
      return;
    }
    history.goBack();
  }

  return (
    <Layout title={layoutTitles.addMaterial} breadCrumbItems={breadCrumbItems}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* Header */}
        <BodyHeader title={ADD_MATERIAL_PAGE_TITLE} buttons={headerButtons} showBackButton onBackClick={handleBackClick} />
       
        <ImportModal 
        open={openImportModal} 
        setOpen={setOpenImportModal}
        refetch={() => {
          history.push('/materials/list')
        }}
        />

        <MutateMaterialForm
          isLoading={isLoading}
          tabs={tabs}
          control={control}
          materialFormOneData={materialFormOneData}
          materialFormTwoData={materialFormTwoData}
          errors={errors}
          handleFileChange={handleFileChange}
          removeFile={removeFile}
          documents={documents}
          attachments={attachments}
          setSelectedAttachmentType={setSelectedAttachmentType}
        />
      </form>

      {/* Modals */}
      <CancelConfirmModal
        open={openCancelConfirmModal}
        setOpen={setOpenCancelConfirmModal}
        saveMaterial={saveMaterial}
        dontSave={discardChanges} />

      <HelpModal
        open={openHelpModal}
        setOpen={setOpenHelpModal}
        title={MaterialHelp.title}
        content={MaterialHelp.content} />
    </Layout>
  );
};

export default MaterialNew;
