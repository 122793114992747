import React, { useEffect, useState } from "react";
import Button, { ButtonTypes } from "../../../../common/Button";
import {
  useForm,
  Controller,
  useFieldArray,
  useFormContext,
} from "react-hook-form";
import MaterialService from "../../../../../services/materialService";
import { ensureArray } from "../../../../../utils/common";

interface ChemicalPurityType {
  impurity: string;
  method: string;
  value: number;
  unit: string;
}

interface ResidualSolventType {
  solvent: string;
  method: string;
  value: number;
  unit: string;
  test_method: string;
}

interface HeavyMetalType {
  metal: string;
  method: string;
  value: number;
  unit: string;
  test_method: string;
}

interface ImpurityResidualsType {
  test_method: string;
  related_eln_experiments: string;
  assay: string;
  water: string;
  chemical_purities: ChemicalPurityType[];
  residual_solvents: ResidualSolventType[];
  heavy_metals: HeavyMetalType[];
}

const ImpurityResiduals = (props: any) => {
  const { control, handleSubmit, setValue, watch } = useForm<any>({
    defaultValues: props.initialData,
  });

  const methods = useFormContext();
  const [solvents, setSolvents] = useState<any>([]);
  const [elnExperiments, setElnExperiments] = useState<string[]>(
    ensureArray(
      props.readable
        ? props.initialData.related_eln_experiments
        : methods?.control?._formValues?.impurity_and_residuals
            ?.related_eln_experiments
    )
  );
  const [inputValue, setInputValue] = useState("");

  const getMaterialNameByDensity = (array:[], densityFromApi:number) => {
    const matchingObject:any = array.find((obj:{density:number}) => obj.density === densityFromApi);
    return matchingObject ? matchingObject.material_name : null;
  }

  const handleAddExperiment = () => {
    if (inputValue.trim()) {
      setElnExperiments([...elnExperiments, inputValue]);
      methods.setValue("impurity_and_residuals.related_eln_experiments", [
        ...elnExperiments,
        inputValue,
      ]);
      setInputValue("");
    }
  };

  const handleRemoveExperiment = (indexToRemove: number) => {
    const updatedExperiments = elnExperiments.filter(
      (_, index) => index !== indexToRemove
    );
    setElnExperiments(updatedExperiments);
    methods.setValue(
      "impurity_and_residuals.related_eln_experiments",
      updatedExperiments
    );
  };

  const {
    fields: chemical_purities,
    append: addChemicalPurityRow,
    remove: deleteChemicalPurityRow,
  } = useFieldArray({
    control,
    name: "chemical_purities", // dynamic name for chemicalPurities array
  });

  const {
    fields: residual_solvent_rows,
    append: addResidualSolventRow,
    remove: deleteResidualSolventRow,
  } = useFieldArray({
    control,
    name: "residual_solvents", // dynamic name for residualSolventRows array
  });

  const {
    fields: heavy_metal_rows,
    append: addHeavyMetalRow,
    remove: deleteHeavyMetalRow,
  } = useFieldArray({
    control,
    name: "heavy_metals", // dynamic name for heavyMetals array
  });

  useEffect(() => {
    MaterialService.getSolvents().then((res) => {
      if (res.status === 200 && res.data.body.solvensts) {
        setSolvents(res.data.body.solvensts || []);
      }
    });
  }, []);

  useEffect(() => {
    const defaultChemicalPurities = [
      { impurity: "", method: "", value: null, unit: "" },
    ];

    const defaultResidualSolvents = [
      { solvent: "", method: "", value: null, unit: "", test_method: "" },
    ];

    const defaultHeavyMetals = [
      { metal: "", method: "", value: null, unit: "", test_method: "" },
    ];

    if (props?.readable) {
      setValue(
        "chemical_purities",
        props.initialData.chemical_purities?.length > 0
          ? props.initialData.chemical_purities
          : defaultChemicalPurities
      );
      setValue(
        "residual_solvents",
        props.initialData.residual_solvents?.length > 0
          ? props.initialData.residual_solvents
          : defaultResidualSolvents
      );
      setValue(
        "heavy_metals",
        props.initialData.heavy_metals?.length > 0
          ? props.initialData.heavy_metals
          : defaultHeavyMetals
      );
    } else {
      const impurityAndResiduals =
        methods.control._formValues?.impurity_and_residuals;

      setValue(
        "chemical_purities",
        impurityAndResiduals?.chemical_purities?.length > 0
          ? impurityAndResiduals?.chemical_purities
          : defaultChemicalPurities
      );
      setValue(
        "residual_solvents",
        impurityAndResiduals?.residual_solvents?.length > 0
          ? impurityAndResiduals?.residual_solvents
          : defaultResidualSolvents
      );
      setValue(
        "heavy_metals",
        impurityAndResiduals?.heavy_metals?.length > 0
          ? impurityAndResiduals?.heavy_metals
          : defaultHeavyMetals
      );
    }
  }, [props?.readable]);

  return (
    <div className="container mx-auto p-4 space-y-8">
      {/* Test Method and Related ELN Experiments */}
      <h2 className="text-xl font-semibold mb-4">Impurity and Residuals</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-6">
        <div className="input-wrap w-full">
          <label className="ip-wrap" htmlFor="testMethod">
            <span className="form-label">Test Method</span>
          </label>
          {props.readable ? (
            <p className="theme-ip pt-3 disabled">
              {control?._formValues?.test_method || ""}
            </p>
          ) : (
            <Controller
              control={methods.control}
              name="impurity_and_residuals.test_method"
              render={({ field }) => (
                <input
                  type="text"
                  {...field}
                  required
                  className="theme-ip w-full"
                />
              )}
            />
          )}
        </div>

        {props.readable ? (
          ""
        ) : (
          <div className="input-wrap w-full">
            <label className="ip-wrap" htmlFor="relatedElnExperiments">
              <span className="form-label">Sample Name</span>
            </label>
            <input
              type="text"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              className="theme-ip w-full"
              placeholder="Enter sample name"
            />
          </div>
        )}

        <div className="input-wrap w-full flex flex-col space-y-2">
          <div className="flex items-center space-x-2 gap-2">
            {props.readable ? (
              ""
            ) : (
              <button
                title="Add"
                type="button"
                onClick={handleAddExperiment}
                className={`theme-btn sm ${
                  elnExperiments?.length > 0 ? "" : "mt-5"
                }`}
              >
                Add
              </button>
            )}
            <div className="" style={{ flex: "2" }}>
              <label className="ip-wrap">
                <span className="form-label ">Samples</span>
              </label>
              {elnExperiments?.length ? (
                <div
                  className={`overflow-y-auto border p-2 theme-ip ${
                    props?.readable ? "disabled" : ""
                  }`}
                  style={{
                    maxHeight: "60px",
                    minHeight: "60px",
                  }}
                >
                  {elnExperiments?.map((experiment, index) => {
                    if (experiment === "") return;
                    return (
                      <div
                        key={index}
                        className="text-sm flex justify-between items-center"
                      >
                        <span>{experiment}</span>
                        {props.readable ? (
                          ""
                        ) : (
                          <button
                            title="Remove"
                            onClick={() => handleRemoveExperiment(index)}
                            className="text-red-500 hover:text-red-700"
                          >
                            <i className="fa-solid fa-xmark" />
                          </button>
                        )}
                      </div>
                    );
                  })}
                </div>
              ) : (
                <p className="theme-ip pt-3 disabled"></p>
              )}
            </div>
          </div>
        </div>
      </div>

      <h2 className="text-xl font-semibold mb-4">Chemical Purity</h2>
      {/* Add Chemical Purity Button */}
      {props.readable ? (
        ""
      ) : (
        <div className="mt-4">
          <Button
            title="+ Add Chemical Purity"
            navigateTo="# "
            isIconButton={false}
            onClick={() => {
              addChemicalPurityRow({
                impurity: "",
                method: "",
                value: 0,
                unit: "",
              });
            }}
            type={ButtonTypes.PRIMARY}
            className="text-red-500 font-bold hover:text-red-700"
          />
        </div>
      )}

      {/* Chemical Purity Table */}
      <div className="overflow-y-auto" style={{ maxHeight: "300px" }}>
        <table className="min-w-full bg-white border border-gray-300">
          <thead>
            <tr className="border-b">
              <th className={`px-2 py-2 border-b ${props.readable ? "text-left" : "text-center"}`}>Impurity</th>
              <th className={`px-2 py-2 border-b ${props.readable ? "text-left" : "text-center"}`}>Method</th>
              <th className={`px-2 py-2 border-b ${props.readable ? "text-left" : "text-center"}`}>Value</th>
              <th className={`px-2 py-2 border-b ${props.readable ? "text-left" : "text-center"}`}>Unit</th>
              {props.readable ? (
                ""
              ) : (
                <th className="px-2 py-2 border-b text-center">Actions</th>
              )}
            </tr>
          </thead>
          <tbody>
            {chemical_purities.map((row, index) => (
              <tr key={row.id} className="border-b">
                <td className="px-2 py-2 justify-center">
                  {props.readable ? (
                    <p className="theme-ip pt-3 disabled">
                      {control?._formValues?.chemical_purities[index]
                        .impurity || ""}
                    </p>
                  ) : (
                    <Controller
                      control={methods.control}
                      name={`impurity_and_residuals.chemical_purities[${index}].impurity`}
                      render={({ field }) => (
                        <input
                          type="text"
                          {...field}
                          className="theme-ip w-full"
                        />
                      )}
                    />
                  )}
                </td>
                <td className="px-2 py-2 justify-center">
                  {props.readable ? (
                    <p className="theme-ip pt-3 disabled">
                      {control?._formValues?.chemical_purities[index].method ||
                        ""}
                    </p>
                  ) : (
                    <Controller
                      control={methods.control}
                      name={`impurity_and_residuals.chemical_purities[${index}].method`}
                      render={({ field }) => (
                        <input
                          type="text"
                          {...field}
                          className="theme-ip w-full"
                        />
                      )}
                    />
                  )}
                </td>
                <td className="px-2 py-2 justify-center">
                  {props.readable ? (
                    <p className="theme-ip pt-3 disabled">
                      {control?._formValues?.chemical_purities[index].value ||
                        ""}
                    </p>
                  ) : (
                    <Controller
                      control={methods.control}
                      name={`impurity_and_residuals.chemical_purities[${index}].value`}
                      render={({ field }) => (
                        <input
                          type="number"
                          {...field}
                          className="theme-ip w-full"
                        />
                      )}
                    />
                  )}
                </td>
                <td className="px-2 py-2 justify-center">
                  {props.readable ? (
                    <p className="theme-ip pt-3 disabled">
                      {control?._formValues?.chemical_purities[index].unit ||
                        ""}
                    </p>
                  ) : (
                    <Controller
                      control={methods.control}
                      name={`impurity_and_residuals.chemical_purities[${index}].unit`}
                      render={({ field }) => (
                        <input
                          type="text"
                          {...field}
                          className="theme-ip w-full"
                        />
                      )}
                    />
                  )}
                </td>
                {props.readable ? (
                  ""
                ) : (
                  <td className="px-2 py-2 justify-center text-center">
                    <button
                      type="button"
                      onClick={() => {
                        deleteChemicalPurityRow(index);
                        methods.setValue(
                          "impurity_and_residuals.chemical_purities",
                          chemical_purities.filter((_, i) => i !== index)
                        );
                      }}
                      className="text-red-500 hover:text-red-700"
                    >
                      Delete
                    </button>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Assay and Water */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-6">
        <div className="input-wrap w-full">
          <label className="ip-wrap" htmlFor="assay">
            <span className="form-label">Assay (% w/w)</span>
          </label>
          {props.readable ? (
            <p className="theme-ip pt-3 disabled">
              {control?._formValues?.assay || ""}
            </p>
          ) : (
            <Controller
              control={methods.control}
              name="impurity_and_residuals.assay"
              render={({ field }) => (
                <input type="text" {...field} className="theme-ip w-full" />
              )}
            />
          )}
        </div>
        <div className="input-wrap w-full">
          <label className="ip-wrap" htmlFor="water">
            <span className="form-label">Water (% w/w)</span>
          </label>
          {props.readable ? (
            <p className="theme-ip pt-3 disabled">
              {control?._formValues?.water || ""}
            </p>
          ) : (
            <Controller
              control={methods.control}
              name="impurity_and_residuals.water"
              render={({ field }) => (
                <input type="text" {...field} className="theme-ip w-full" />
              )}
            />
          )}
        </div>
      </div>

      <div className="divider"></div>

      {/* Residual Solvents Table */}
      <h2 className="text-xl font-semibold mb-4">Residual Solvents</h2>
      {props.readable ? (
        ""
      ) : (
        <div className="mt-4">
          <Button
            title="+ Add Residual Solvent"
            navigateTo="#"
            isIconButton={false}
            onClick={() => {
              addResidualSolventRow({
                solvent: "",
                method: "",
                value: 0,
                unit: "",
                test_method: "",
                sample_name: "",
              });
            }}
            type={ButtonTypes.PRIMARY}
            className="text-red-500 font-bold hover:text-red-700"
          />
        </div>
      )}

      <div className="overflow-y-auto" style={{ maxHeight: "300px" }}>
        <table className="min-w-full bg-white border border-gray-300">
          <thead>
            <tr className="border-b">
              <th className={`px-2 py-2 border-b ${props.readable ? "text-left" : "text-center"}`}>
                Residual Solvent
              </th>
              <th className={`px-2 py-2 border-b ${props.readable ? "text-left" : "text-center"}`}>Method</th>
              <th className={`px-2 py-2 border-b ${props.readable ? "text-left" : "text-center"}`}>Value</th>
              <th className={`px-2 py-2 border-b ${props.readable ? "text-left" : "text-center"}`}>Unit</th>
              <th className={`px-2 py-2 border-b ${props.readable ? "text-left" : "text-center"}`}>Test Method</th>
              <th className={`px-2 py-2 border-b ${props.readable ? "text-left" : "text-center"}`}>Sample Name</th>
              {props.readable ? (
                ""
              ) : (
                <th className="px-2 py-2 border-b text-center">Actions</th>
              )}
            </tr>
          </thead>
          <tbody>
            {residual_solvent_rows.map((row, index) => (
              <tr key={row.id} className="border-b">
                <td className="px-2 py-2 justify-center">
                  <div className="input-wrap select-outer">
                    {props.readable ? (
                      <p className="theme-ip pt-3 disabled">
                        {getMaterialNameByDensity(solvents , control?._formValues?.residual_solvents[index]
                          .solvent || "")}
                      </p>
                    ) : (
                      <Controller
                        control={methods.control}
                        name={`impurity_and_residuals.residual_solvents[${index}].solvent`}
                        render={({ field }) => (
                          <select {...field} className="theme-ip w-full">
                            <option value="">Select</option>
                            {solvents.map((solvent: any) => (
                              <option value={solvent.density}>
                                {solvent.material_name}
                              </option>
                            ))}
                          </select>
                        )}
                      />
                    )}
                  </div>
                </td>
                <td className="px-2 py-2 justify-center">
                  {props.readable ? (
                    <p className="theme-ip pt-3 disabled">
                      {control?._formValues?.residual_solvents[index].method ||
                        ""}
                    </p>
                  ) : (
                    <Controller
                      control={methods.control}
                      name={`impurity_and_residuals.residual_solvents[${index}].method`}
                      render={({ field }) => (
                        <input
                          type="text"
                          {...field}
                          className="theme-ip w-full"
                        />
                      )}
                    />
                  )}
                </td>
                <td className="px-2 py-2 justify-center">
                  {props.readable ? (
                    <p className="theme-ip pt-3 disabled">
                      {control?._formValues?.residual_solvents[index].value ||
                        ""}
                    </p>
                  ) : (
                    <Controller
                      control={methods.control}
                      name={`impurity_and_residuals.residual_solvents[${index}].value`}
                      render={({ field }) => (
                        <input
                          type="number"
                          {...field}
                          className="theme-ip w-full"
                        />
                      )}
                    />
                  )}
                </td>
                <td className="px-2 py-2 justify-center">
                  {props.readable ? (
                    <p className="theme-ip pt-3 disabled">
                      {control?._formValues?.residual_solvents[index].unit ||
                        ""}
                    </p>
                  ) : (
                    <Controller
                      control={methods.control}
                      name={`impurity_and_residuals.residual_solvents[${index}].unit`}
                      render={({ field }) => (
                        <input
                          type="text"
                          {...field}
                          className="theme-ip w-full"
                        />
                      )}
                    />
                  )}
                </td>
                <td className="px-2 py-2 justify-center">
                  {props.readable ? (
                    <p className="theme-ip pt-3 disabled">
                      {control?._formValues?.residual_solvents[index]
                        .test_method || ""}
                    </p>
                  ) : (
                    <Controller
                      control={methods.control}
                      name={`impurity_and_residuals.residual_solvents[${index}].test_method`}
                      render={({ field }) => (
                        <input
                          type="text"
                          {...field}
                          className="theme-ip w-full"
                        />
                      )}
                    />
                  )}
                </td>
                <td className="px-2 py-2 justify-center">
                  {props.readable ? (
                    <p className="theme-ip pt-3 disabled">
                      {control?._formValues?.residual_solvents[index]
                        .sample_name || ""}
                    </p>
                  ) : (
                    <Controller
                      control={methods.control}
                      name={`impurity_and_residuals.residual_solvents[${index}].sample_name`}
                      render={({ field }) => (
                        <input
                          type="text"
                          {...field}
                          className="theme-ip w-full"
                        />
                      )}
                    />
                  )}
                </td>
                {props.readable ? (
                  ""
                ) : (
                  <td className="px-2 py-2 justify-center text-center">
                    <button
                      type="button"
                      onClick={() => {
                        deleteResidualSolventRow(index);
                        methods.setValue(
                          "impurity_and_residuals.residual_solvents",
                          residual_solvent_rows.filter((_, i) => i !== index)
                        );
                      }}
                      className="text-red-500 hover:text-red-700"
                    >
                      Delete
                    </button>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="divider"></div>

      {/* Heavy Metals Table */}
      <h2 className="text-xl font-semibold mb-4">Heavy Metals</h2>
      {props.readable ? (
        ""
      ) : (
        <div className="mt-4">
          <Button
            title="+ Add Heavy Metal"
            navigateTo="#"
            isIconButton={false}
            onClick={() => {
              addHeavyMetalRow({
                metal: "",
                method: "",
                value: 0,
                unit: "",
                test_method: "",
                sample_name: "",
              });
            }}
            type={ButtonTypes.PRIMARY}
            className="text-red-500 font-bold hover:text-red-700"
          />
        </div>
      )}

      <div className="overflow-y-auto" style={{ maxHeight: "300px" }}>
        <table className="min-w-full bg-white border border-gray-300">
          <thead>
            <tr className="border-b">
              <th className={`px-2 py-2 border-b ${props.readable ? "text-left" : "text-center"}`}>Metal</th>
              <th className={`px-2 py-2 border-b ${props.readable ? "text-left" : "text-center"}`}>Method</th>
              <th className={`px-2 py-2 border-b ${props.readable ? "text-left" : "text-center"}`}>Value</th>
              <th className={`px-2 py-2 border-b ${props.readable ? "text-left" : "text-center"}`}>Unit</th>
              <th className={`px-2 py-2 border-b ${props.readable ? "text-left" : "text-center"}`}>Test Method</th>
              <th className={`px-2 py-2 border-b ${props.readable ? "text-left" : "text-center"}`}>Sample Name</th>
              {props.readable ? (
                ""
              ) : (
                <th className="px-2 py-2 border-b text-center">Actions</th>
              )}
            </tr>
          </thead>
          <tbody>
            {heavy_metal_rows.map((row, index) => (
              <tr key={row.id} className="border-b">
                <td className="px-2 py-2 justify-center">
                  {props.readable ? (
                    <p className="theme-ip pt-3 disabled">
                      {control?._formValues?.heavy_metals[index].metal || ""}
                    </p>
                  ) : (
                    <Controller
                      control={methods.control}
                      name={`impurity_and_residuals.heavy_metals[${index}].metal`}
                      render={({ field }) => (
                        <input
                          type="text"
                          {...field}
                          className="theme-ip w-full"
                        />
                      )}
                    />
                  )}
                </td>
                <td className="px-2 py-2 justify-center">
                  {props.readable ? (
                    <p className="theme-ip pt-3 disabled">
                      {control?._formValues?.heavy_metals[index].method || ""}
                    </p>
                  ) : (
                    <Controller
                      control={methods.control}
                      name={`impurity_and_residuals.heavy_metals[${index}].method`}
                      render={({ field }) => (
                        <input
                          type="text"
                          {...field}
                          className="theme-ip w-full"
                        />
                      )}
                    />
                  )}
                </td>
                <td className="px-2 py-2 justify-center">
                  {props.readable ? (
                    <p className="theme-ip pt-3 disabled">
                      {control?._formValues?.heavy_metals[index].value || ""}
                    </p>
                  ) : (
                    <Controller
                      control={methods.control}
                      name={`impurity_and_residuals.heavy_metals[${index}].value`}
                      render={({ field }) => (
                        <input
                          type="number"
                          {...field}
                          className="theme-ip w-full"
                        />
                      )}
                    />
                  )}
                </td>
                <td className="px-2 py-2 justify-center">
                  {props.readable ? (
                    <p className="theme-ip pt-3 disabled">
                      {control?._formValues?.heavy_metals[index].unit || ""}
                    </p>
                  ) : (
                    <Controller
                      control={methods.control}
                      name={`impurity_and_residuals.heavy_metals[${index}].unit`}
                      render={({ field }) => (
                        <input
                          type="text"
                          {...field}
                          className="theme-ip w-full"
                        />
                      )}
                    />
                  )}
                </td>
                <td className="px-2 py-2 justify-center">
                  {props.readable ? (
                    <p className="theme-ip pt-3 disabled">
                      {control?._formValues?.heavy_metals[index].test_method ||
                        ""}
                    </p>
                  ) : (
                    <Controller
                      control={methods.control}
                      name={`impurity_and_residuals.heavy_metals[${index}].test_method`}
                      render={({ field }) => (
                        <input
                          type="text"
                          {...field}
                          className="theme-ip w-full"
                        />
                      )}
                    />
                  )}
                </td>
                <td className="px-2 py-2 justify-center">
                  {props.readable ? (
                    <p className="theme-ip pt-3 disabled">
                      {control?._formValues?.heavy_metals[index].sample_name ||
                        ""}
                    </p>
                  ) : (
                    <Controller
                      control={methods.control}
                      name={`impurity_and_residuals.heavy_metals[${index}].sample_name`}
                      render={({ field }) => (
                        <input
                          type="text"
                          {...field}
                          className="theme-ip w-full"
                        />
                      )}
                    />
                  )}
                </td>
                {props.readable ? (
                  ""
                ) : (
                  <td className="px-2 py-2 justify-center text-center">
                    <button
                      type="button"
                      onClick={() => {
                        deleteHeavyMetalRow(index);
                        methods.setValue(
                          "impurity_and_residuals.heavy_metals",
                          heavy_metal_rows.filter((_, i) => i !== index)
                        );
                      }}
                      className="text-red-500 hover:text-red-700"
                    >
                      Delete
                    </button>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ImpurityResiduals;
