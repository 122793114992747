import { cleanFileName } from "./common";

export enum VisibleModuleType {
  VISUALIZE = "VISUALIZE",
  EXPORT = "EXPORT",
}

export const attachmentsTypes = [
  { label: "Molecular Structure", value: "Molecular Structure" },
  { label: "USP Monograph", value: "USP Monograph" },
  { label: "Chemical Safety", value: "Chemical Safety" },
  { label: "HPLC Method", value: "HPLC Method" },
  { label: "SMILES", value: "SMILES" },
  { label: "Others", value: "Others" },
];

export const solidAttachmentsTypes = [
  { label: "COSMO File", value: "COSMO File" },
  { label: "Crystal Structure", value: "Crystal Structure" },
  { label: "XRPD", value: "XRPD" },
];

export const vendorNames = [
  { label: "Cambrex", value: "Cambrex" },
  { label: "Generic", value: "Generic" },
  { label: "J.T. Baker", value: "J.T. Baker" },
  { label: "Koptec", value: "Koptec" },
  { label: "Nalas", value: "Nalas" },
  { label: "SigmaAldrich", value: "SigmaAldrich" },
  { label: "Takeda", value: "Takeda" },
];

export const materialSolubilityFileFields: any = {
  model3: "Model 3",
  unchainedLab: "Unchained Lab Data",
  hplc: "HPLC Data",
  crystal16: "Crystal 16 Data",
  crystalline: "Crystalline Data",
  gravimetryMethod: "Gravimetry Method",
  solubilityCurve1: "Solubility Curve 1",
  solubilityCurve2: "Solubility Curve 2",
  solubilityCurve3: "Solubility Curve 3",
  solubilityCurve4: "Solubility Curve 4",
  solubilityCurve5: "Solubility Curve 5",
};

export const disabledFields = [
  {
    name: "Material Name",
    value: "material_name",
    visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
  },
  {
    name: "Phase",
    value: "phase",
    visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
  },
  {
    name: "Lot Number",
    value: "lot_number",
    visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
  },
  {
    name: "Vendor",
    value: "vendor_name",
    visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
  },
];

export const materialGeneralFields = [
  {
    name: "Material Name",
    value: "material_name",
    visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    disabled: true,
  },
  {
    name: "Phase",
    value: "phase",
    visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    disabled: true,
  },
  {
    name: "Lot Number",
    value: "lot_number",
    visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    disabled: true,
  },
  {
    name: "Vendor",
    value: "vendor_name",
    visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    disabled: true,
  },
  {
    name: "Boiling Point (C)",
    value: "boiling_point",
    visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
  },
  {
    name: "Manufacturing Number",
    value: "manufacturing_number",
    visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
  },
  {
    name: "Abbreviation",
    value: "abbreviation",
    visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
  },
  {
    name: "Brand name",
    value: "brand_name",
    visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
  },
  {
    name: "Synonyms",
    value: "synonyms",
    visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
  },
  {
    name: "CAS Number",
    value: "cas_number",
    visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
  },
  {
    name: "Chemical Formula",
    value: "chemical_formula",
    visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
  },
  {
    name: "Chemical Safety",
    value: "chemical_safety",
    visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
  },
  {
    name: "Decomposition/Shelf-life (month)",
    value: "shelf_life",
    visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
  },
  {
    name: "Incompatibility",
    value: "incompatibility",
    visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
  },
  {
    name: "Melting Point (C)",
    value: "melting_point",
    visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
  },
  {
    name: "Molecular Weight (g/mol)",
    value: "molecular_weight",
    visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
  },
  {
    name: "Price ($/g)",
    value: "price",
    visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
  },
  {
    name: "Purity (%)",
    value: "purity",
    visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
  },
  {
    name: "SMILES",
    value: "smiles",
    visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
  },
  {
    name: "Regulatory Filing Data",
    value: "regulatory_filing_data",
    visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
  },
  {
    name: "Process Name",
    value: "process_name",
    visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
  },
  {
    name: "Project Name",
    value: "project_name",
    visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
  },
];

export const solidGeneralFields: any = {
  general: [
    {
      name: "Solid Form",
      value: "solid_form",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Type",
      value: "type",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Application",
      value: "application",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Bulk Modulus (Pa)",
      value: "bulk_modulus",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Color-L",
      value: "color_l",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Color-A",
      value: "color_a",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Color-B",
      value: "color_b",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Counterion",
      value: "counterion",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Heat of Fusion (kJ/mol)",
      value: "heat_of_fusion",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Log S",
      value: "log_S",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Main Impurity 1",
      value: "main_impurity_1",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Main Impurity 2",
      value: "main_impurity_2",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Main Impurity 3",
      value: "main_impurity_3",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "PKA 1",
      value: "pKa_1",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "PKA 2",
      value: "pKa_2",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "PKA 3",
      value: "pKa_3",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Specific Heat Capacity (Cp) (kJ/K)",
      value: "specific_heat_capacity",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Surface Area (m2)",
      value: "surface_area",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Surface Energy (J/m2)",
      value: "surface_energy",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Thermal Conductivity (W/mK)",
      value: "thermal_conductivity",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Yield Stress (N/m2)",
      value: "yield_stress",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: `Young's Modulus (Pa)`,
      value: "young_modulus",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Form",
      value: "form",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Polymorph",
      value: "polymorph",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Crystal Shape",
      value: "crystal_shape",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Glass Transition Temperature (C)",
      value: "glass_transition_temperature",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
  ],
  solubility: [
    {
      name: "Main Solvent",
      value: "main_solvent",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Second Solvent",
      value: "second_solvent",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Main Solvent Temperature (C)",
      value: "main_solvent_temperature",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Second Solvent Temperature (C)",
      value: "second_solvent_temperature",
      visibleModule: [VisibleModuleType.EXPORT],
    },
    {
      name: "Solubility in the main Solvent (mg/ml)",
      value: "solubility_in_main_solvent",
      visibleModule: [VisibleModuleType.EXPORT],
    },
    {
      name: "Solubility in the Second Solvent (mg/ml)",
      value: "solubility_in_second_solvent",
      visibleModule: [VisibleModuleType.EXPORT],
    },
    {
      name: "Main Solvent Reference",
      value: "main_solvent_reference",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Second Solvent Reference",
      value: "second_solvent_reference",
      visibleModule: [VisibleModuleType.EXPORT],
    },
  ],
  environment: [
    {
      name: "Metric 1",
      value: "metric1",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Metric 2",
      value: "metric2",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Metric 3",
      value: "metric3",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Metric 4",
      value: "metric4",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
  ],
  impurity_and_residuals: [
    {
      name: "Test Method",
      value: "test_method",
      visibleModule: [VisibleModuleType.EXPORT],
    },
    {
      name: "Sample Names",
      value: "related_eln_experiments",
      visibleModule: [VisibleModuleType.EXPORT , VisibleModuleType.VISUALIZE],
    },
    {
      name: "Assay (% w/w)",
      value: "assay",
      visibleModule: [VisibleModuleType.EXPORT],
    },
    {
      name: "Water (% w/w)",
      value: "water",
      visibleModule: [VisibleModuleType.EXPORT],
    },
    {
      name: "Chemical Purities",
      value: "chemical_purities",
      visibleModule: [VisibleModuleType.EXPORT , VisibleModuleType.VISUALIZE],
      subFields: [
        {
          name: "Impurity",
          value: "impurity",
        },
        {
          name: "Method",
          value: "method",
        },
        {
          name: "Value",
          value: "value",
        },
        {
          name: "Unit",
          value: "unit",
        },
      ],
    },
    {
      name: "Residual Solvents",
      value: "residual_solvents",
      visibleModule: [VisibleModuleType.EXPORT , VisibleModuleType.VISUALIZE],
      subFields: [
        {
          name: "Solvent",
          value: "solvent",
        },
        {
          name: "Method",
          value: "method",
        },
        {
          name: "Value",
          value: "value",
        },
        {
          name: "Unit",
          value: "unit",
        },
        {
          name: "Test Method",
          value: "test_method",
        },
        {
          name: "Sample Name",
          value: "sample_name",
        },
      ],
    },
    {
      name: "Heavy Metals",
      value: "heavy_metals",
      visibleModule: [VisibleModuleType.EXPORT , VisibleModuleType.VISUALIZE],
      subFields: [
        {
          name: "Metal",
          value: "metal",
        },
        {
          name: "Method",
          value: "method",
        },
        {
          name: "Value",
          value: "value",
        },
        {
          name: "Unit",
          value: "unit",
        },
        {
          name: "Test Method",
          value: "test_method",
        },
        {
          name: "Sample Name",
          value: "sample_name",
        },
      ],
    },
  ],
  aeration: [
    {
      name: "Aeration Ratio (4mm/s)",
      value: "aeration_ratio",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Aeration Energy (mJ)",
      value: "aeration_energy",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "ELN Number",
      value: "eln_number",
      visibleModule: [VisibleModuleType.EXPORT],
    },
    {
      name: "Regulatroy Filing",
      value: "regulatory_filing",
      visibleModule: [VisibleModuleType.EXPORT],
    },
    {
      name: "Method Name/#",
      value: "method_name",
      visibleModule: [VisibleModuleType.EXPORT],
    },
  ],
  compressibility: [
    {
      name: "Conditionated Bulk Density (g/ml)",
      value: "conditional_bulk_density",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Compressibility CPS 15 (%)",
      value: "compressibility_value",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Density at 15 kPa (g/ml)",
      value: "density",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "ELN Number",
      value: "eln_number",
      visibleModule: [VisibleModuleType.EXPORT],
    },
    {
      name: "Regulatroy Filing",
      value: "regulatory_filing",
      visibleModule: [VisibleModuleType.EXPORT],
    },
    {
      name: "Method Name/#",
      value: "method_name",
      visibleModule: [VisibleModuleType.EXPORT],
    },
  ],
  densities: [
    {
      name: "Bulk Density (g/ml)",
      value: "bulk_densities",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Tapped Density (g/ml)",
      value: "tapped_density",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "ELN Number",
      value: "eln_number",
      visibleModule: [VisibleModuleType.EXPORT],
    },
    {
      name: "Regulatroy Filing",
      value: "regulatory_filing",
      visibleModule: [VisibleModuleType.EXPORT],
    },
    {
      name: "Method Number",
      value: "method_number",
      visibleModule: [VisibleModuleType.EXPORT],
    },
  ],
  drug_info: [
    {
      name: "Dosage Form",
      value: "dosage_form",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
  ],
  flow_energy: [
    {
      name: "Basic Flow Energy (mJ)",
      value: "basic_flow_energy",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Conditionated Bulk Density (g/ml)",
      value: "conditional_bulk_density",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Stability Index",
      value: "stability_index",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Specific Energy (mJ/g)",
      value: "specific_energy",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Flow rate Index",
      value: "flow_rate_index",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Method Name/#",
      value: "method_name",
      visibleModule: [VisibleModuleType.EXPORT],
    },
    {
      name: "ELN Number",
      value: "eln_number",
      visibleModule: [VisibleModuleType.EXPORT],
    },
    {
      name: "Regulatroy Filing",
      value: "regulatory_filing",
      visibleModule: [VisibleModuleType.EXPORT],
    },
  ],
  hygroscopicity: [
    {
      name: "Mass Uptake 25C/80%RH",
      value: "mass_update",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Category",
      value: "category",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "ELN Number",
      value: "eln_number",
      visibleModule: [VisibleModuleType.EXPORT],
    },
    {
      name: "Method Name/#",
      value: "method_name",
      visibleModule: [VisibleModuleType.EXPORT],
    },
    {
      name: "Regulatroy Filing",
      value: "regulatory_filing",
      visibleModule: [VisibleModuleType.EXPORT],
    },
  ],
  morphologi: [
    {
      name: "Aspect Ratio D10",
      value: "aspect_ratio",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Aspect Ratio D50",
      value: "aeration_ratio_d50",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Aspect Ratio D90",
      value: "aeration_ratio_d90",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "ELN Number",
      value: "eln_number",
      visibleModule: [VisibleModuleType.EXPORT],
    },
    {
      name: "Method Name/#",
      value: "method_name",
      visibleModule: [VisibleModuleType.EXPORT],
    },
    {
      name: "Regulatroy Filing",
      value: "regulatory_filing",
      visibleModule: [VisibleModuleType.EXPORT],
    },
  ],
  permeability: [
    {
      name: "Conditionated Bulk Density (g/ml)",
      value: "conditional_bulk_density",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Density at 15 kPa (g/ml)",
      value: "density",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Permeability K 15,2 x10^-9 (cm2)",
      value: "permeability_value",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Pressure Drop, PD 15,2 (mBar)",
      value: "pressure_drop",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "ELN Number",
      value: "eln_number",
      visibleModule: [VisibleModuleType.EXPORT],
    },
    {
      name: "Method Name/#",
      value: "method_name",
      visibleModule: [VisibleModuleType.EXPORT],
    },
    {
      name: "Regulatroy Filing",
      value: "regulatory_filing",
      visibleModule: [VisibleModuleType.EXPORT],
    },
  ],
  psd: [
    {
      name: "PSD 10 (mic)",
      value: "psd10",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "PSD 50 (mic)",
      value: "psd50",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "PSD 90 (mic)",
      value: "psd90",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "ELN Number",
      value: "eln_number",
      visibleModule: [VisibleModuleType.EXPORT],
    },
    {
      name: "Method Name/#",
      value: "method_name",
      visibleModule: [VisibleModuleType.EXPORT],
    },
    {
      name: "Regulatroy Filing",
      value: "regulatory_filing",
      visibleModule: [VisibleModuleType.EXPORT],
    },
  ],
  shear_cell: [
    {
      name: "Cohesion (Pa)",
      value: "cohesion",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "FFC",
      value: "FFC",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "ELN Number",
      value: "eln_number",
      visibleModule: [VisibleModuleType.EXPORT],
    },
    {
      name: "Method Name/#",
      value: "method_name",
      visibleModule: [VisibleModuleType.EXPORT],
    },
    {
      name: "Regulatroy Filing",
      value: "regulatory_filing",
      visibleModule: [VisibleModuleType.EXPORT],
    },
  ],
  thermal: [
    {
      name: "KF Water content (w%)",
      value: "kf_water_content_tga",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Mass Loss (w%)",
      value: "mass_loss_tga",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Evaporation Temperature (C)",
      value: "evaporation_temperature_tga",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Degradation Temperature (C)",
      value: "degradation_temperature_tga",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "ELN Number",
      value: "eln_number_tga",
      visibleModule: [VisibleModuleType.EXPORT],
    },
    {
      name: "Method Name/#",
      value: "method_name_tga",
      visibleModule: [VisibleModuleType.EXPORT],
    },
    {
      name: "Regulatroy Filing",
      value: "regulatory_filing_tga",
      visibleModule: [VisibleModuleType.EXPORT],
    },
    {
      name: "Tg (Onset) (C)",
      value: "tg_onset_dsc",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "First Melting Point (C)",
      value: "first_melting_point_dsc",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Second Melting Point (C)",
      value: "second_melting_point_dsc",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Degradation Temperature (C)",
      value: "degradation_temperature_dsc",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "ELN Number",
      value: "eln_number_dsc",
      visibleModule: [VisibleModuleType.EXPORT],
    },
    {
      name: "Method Name/#",
      value: "method_name_dsc",
      visibleModule: [VisibleModuleType.EXPORT],
    },
    {
      name: "Regulatroy Filing",
      value: "regulatory_filing_dsc",
      visibleModule: [VisibleModuleType.EXPORT],
    },
  ],
};

export const solidAttachmentsFields: any = {
  // default: [
  //   {
  //     name: "Molecular Structure",
  //     value: "Molecular Structure",
  //     visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
  //   },
  //   {
  //     name: "Process Attachment",
  //     value: "Process Attachment",
  //     visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
  //   },
  // ],
  // general: [
  //   {
  //     name: "COSMO File",
  //     value: "COSMO File",
  //     visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
  //   },
  // ],
  // spectralInfo: [
  //   {
  //     name: "GC-MS",
  //     value: "gcMS",
  //     visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
  //   },
  //   {
  //     name: "NMR",
  //     value: "nmr",
  //     visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
  //   },
  //   {
  //     name: "ATR-IR",
  //     value: "atrIR",
  //     visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
  //   },
  //   {
  //     name: "IR",
  //     value: "ir",
  //     visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
  //   },
  //   {
  //     name: "LC-MS",
  //     value: "lcMS",
  //     visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
  //   },
  //   {
  //     name: "MS-MS",
  //     value: "msMS",
  //     visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
  //   },
  //   {
  //     name: "Raman",
  //     value: "raman",
  //     visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
  //   },
  // ],
  // environment: [
  //   {
  //     name: "XX Report",
  //     value: "XX Report",
  //     visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
  //   },
  // ],
  PSD: [
    {
      name: "PSD",
      value: "PSD",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Histogram",
      value: "Histogram",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
  ],
  // flow_energy: [
  //   {
  //     name: "Flow Energy",
  //     value: "Flow Energy",
  //     visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
  //   },
  // ],
  // drug_info: [
  //   {
  //     name: "Toxicity Data",
  //     value: "toxicity",
  //     visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
  //   },
  //   {
  //     name: "Clinical Trials Info",
  //     value: "clinical_trials",
  //     visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
  //   },
  //   {
  //     name: "Indication",
  //     value: "Indication",
  //     visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
  //   },
  //   {
  //     name: "ADME",
  //     value: "ADME",
  //     visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
  //   },
  // ],
  // permeability: [
  //   {
  //     name: "Permeability",
  //     value: "Permeability",
  //     visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
  //   },
  // ],
  // shear_cell: [
  //   {
  //     name: "Shear Cell",
  //     value: "Shear Cell",
  //     visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
  //   },
  // ],
  // compressibility: [
  //   {
  //     name: "Compressibility",
  //     value: "Compressibility",
  //     visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
  //   },
  // ],
  // morphologi: [
  //   {
  //     name: "Morphologi G3",
  //     value: "Morphologi G3",
  //     visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
  //   },
  // ],
  // thermal: [
  //   {
  //     name: "SOP File 2",
  //     value: "sopFileTga",
  //     visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
  //   },
  //   {
  //     name: "SOP File",
  //     value: "sopFileDsc",
  //     visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
  //   },
  //   {
  //     name: "Heat Curve",
  //     value: "heatCurveDsc",
  //     visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
  //   },
  //   {
  //     name: "Heat Curve 2",
  //     value: "heatCurveTga",
  //     visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
  //   },
  // ],
  // aeration: [
  //   {
  //     name: "Aeration",
  //     value: "Aeration",
  //     visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
  //   },
  // ],
  SEM: [
    {
      name: "SEM",
      value: "SEM",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Microscopy",
      value: "Microscopy",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
  ],
  // hygroscopicity: [
  //   {
  //     name: "Hygroscopicity 2",
  //     value: "hygroscopicity2",
  //     visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
  //   },
  //   {
  //     name: "Isotherm Curve",
  //     value: "isothermCurve",
  //     visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
  //   },
  // ],
};

export const liquidAttachmentsFields: any = {
  default: [
    {
      name: "Molecular Structure",
      value: "Molecular Structure",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Process Attachment",
      value: "Process Attachment",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
  ],
  spectralInfo: [
    {
      name: "GC-MS",
      value: "gcMS",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "NMR",
      value: "nmr",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "ATR-IR",
      value: "atrIR",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "IR",
      value: "ir",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "LC-MS",
      value: "lcMS",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "MS-MS",
      value: "msMS",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Raman",
      value: "raman",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
  ],
  environment: [
    {
      name: "XX Report",
      value: "XX Report",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
  ],
};

export const liquidGeneralFields: any = {
  general: [
    {
      name: "Autoignition temperature (C)",
      value: "autoignition_temperature",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Type",
      value: "type",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Color-L",
      value: "color_l",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Color-A",
      value: "color_a",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Color-B",
      value: "color_b",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Corrosivity",
      value: "corrosivity",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Density (g/ml)",
      value: "density",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Heat of Combustion (kJ/mol)",
      value: "heat_of_combustion",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Heat of Vaporization (kJ/mol)",
      value: "heat_of_vaporization",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: `Henry's Law Constant (atm.m3/mol)`,
      value: "henry_law_constant",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Ionization Potential (eV)",
      value: "ionization_potential",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Log P",
      value: "log_p",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "PKA",
      value: "pKa",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Refractive Index",
      value: "refractive_index",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Relative Evaporation Rate",
      value: "relative_evaporation_rate",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Specific Heat Capacity (Cp) (kJ/K)",
      value: "specific_heat_capacity",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Surface Tension (N/m)",
      value: "surface_tension",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Vapor Density (g/ml)",
      value: "vapor_density",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Vapor Pressure (Pa)",
      value: "vapor_pressure",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Viscosity (cP)",
      value: "viscosity",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Volatility",
      value: "volatility",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Flash Point",
      value: "flash_point",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
  ],
  solubility: [
    {
      name: "Main Solvent",
      value: "main_solvent",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Solubility in the main Solvent(mg/ml)",
      value: "solubility_in_main_solvent",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Main Solvent Temperature(C)",
      value: "main_solvent_temperature",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Main Solvent Reference",
      value: "main_solvent_reference",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Second Solvent",
      value: "second_solvent",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Solubility in the Second Solvent(mg/ml)",
      value: "solubility_in_second_solvent",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Second Solvent Temperature(C)",
      value: "second_solvent_temperature",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Second Solvent Reference",
      value: "second_solvent_reference",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
  ],
  environment: [
    {
      name: "Metric 1",
      value: "metric1",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Metric 2",
      value: "metric2",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Metric 3",
      value: "metric3",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Metric 4",
      value: "metric4",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
  ],
};

export const gasGeneralFields: any = {
  general: [
    {
      name: "Application",
      value: "application",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Boiling Point(C)",
      value: "boiling_point",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Density(g / ml)",
      value: "density",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Heat of Vaporization(kJ / mol)",
      value: "heat_of_vaporization",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Vapor Density(g / ml)",
      value: "vapor_density",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Viscosity(cp)",
      value: "viscosity",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
  ],
  solubility: [
    {
      name: "Main Solvent",
      value: "main_solvent",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Solubility in the main Solvent(mg/ml)",
      value: "solubility_in_main_solvent",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Main Solvent Temperature(C)",
      value: "main_solvent_temperature",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Main Solvent Reference",
      value: "main_solvent_reference",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Second Solvent",
      value: "second_solvent",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Solubility in the Second Solvent(mg/ml)",
      value: "solubility_in_second_solvent",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Second Solvent Temperature(C)",
      value: "second_solvent_temperature",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Second Solvent Reference",
      value: "second_solvent_reference",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
  ],
  environment: [
    {
      name: "Metric 1",
      value: "metric1",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Metric 2",
      value: "metric2",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Metric 3",
      value: "metric3",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Metric 4",
      value: "metric4",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
  ],
};

export const gasAttachmentsFields: any = {
  general: [
    {
      name: "Molecular Structure",
      value: "Molecular Structure",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Process Attachment",
      value: "Process Attachment",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
  ],
  spectralInfo: [
    {
      name: "GC-MS",
      value: "gcMS",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "NMR",
      value: "nmr",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "ATR-IR",
      value: "atrIR",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "IR",
      value: "ir",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "LC-MS",
      value: "lcMS",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "MS-MS",
      value: "msMS",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
    {
      name: "Raman",
      value: "raman",
      visibleModule: [VisibleModuleType.VISUALIZE, VisibleModuleType.EXPORT],
    },
  ],
  environment: [
    {
      name: "XX Report",
      value: "XX Report",
    },
  ],
};
export const solidPhaseFormStatus = {
  general: false,
  aeration: false,
  compressibility: false,
  densities: false,
  drug_info: false,
  flow_energy: false,
  hygroscopicity: false,
  morphologi: false,
  permeability: false,
  psd: false,
  sem: false,
  shear_cell: false,
  thermal: false,
  impurity_and_residuals : false
};

export const getSolidFormStatus = async (material: any) => {
  const savedSolidMaterial = await sanitizeObj(
    await sanitizeObj(material?.solid ?? {})
  );
  return {
    ...solidPhaseFormStatus,
    ...Object.keys(savedSolidMaterial).reduce((e: any, t: string) => {
      e[t] = true;
      return e;
    }, {}),
  };
};

export const solidInitialFields: any =
  Object.keys(solidGeneralFields).length &&
  Object.keys(solidGeneralFields).reduce((p: any[], q: any) => {
    p[q] = solidGeneralFields[q].reduce((e: any, t: any) => {
      e[t.value] = false;
      return e;
    }, {});
    return p;
  }, []);

export const materialInitialFields = materialGeneralFields.reduce(
  (e: any, t: any) => {
    e[t.value] = false;
    return e;
  },
  {}
);

export const gasInitialFields: any =
  Object.keys(gasGeneralFields).length &&
  Object.keys(gasGeneralFields).reduce((p: any[], q: any) => {
    p[q] = gasGeneralFields[q].reduce((e: any, t: any) => {
      e[t.value] = false;
      return e;
    }, {});
    return p;
  }, []);

export const liquidInitialFields: any =
  Object.keys(liquidGeneralFields).length &&
  Object.keys(liquidGeneralFields).reduce((p: any[], q: any) => {
    p[q] = liquidGeneralFields[q].reduce((e: any, t: any) => {
      e[t.value] = false;
      return e;
    }, {});
    return p;
  }, []);

export const parseAttachments = (attachments: any[]) => {
  return attachments?.map((attachment: any) => ({
    category: attachment.category,
    file: {
      name: attachment.display_name,
      type: attachment.mime_type,
    },
    error: false,
    isUpload: true,
  }));
};

export const solidFormNumberFields = {
  general: [
    "color_l",
    "color_a",
    "color_b",
    "bulk_modulus",
    "heat_of_fusion",
    "log_s",
    "pKa_1",
    "pKa_2",
    "pKa_3",
    "specific_heat_capacity",
    "surface_area",
    "surface_energy",
    "thermal_conductivity",
    "yield_stress",
    "young_modulus",
    "glass_transition_temperature",
  ],
  aeration: ["aeration_ratio", "aeration_energy"],
  compressibility: [
    "conditional_bulk_density",
    "compressibility_value",
    "density",
  ],
  densities: ["bulk_densities", "tapped_density", "method_number"],
  drug_info: [],
  flow_energy: [
    "basic_flow_energy",
    "conditional_bulk_density",
    "flow_rate_index",
    "specific_energy",
    "stability_index",
  ],
  hygroscopicity: ["mass_update"],
  morphologi: ["aspect_ratio", "aeration_ratio_d50", "aeration_ratio_d90"],
  permeability: [
    "conditional_bulk_density",
    "permeability_value",
    "density",
    "pressure_drop",
  ],
  psd: ["psd10", "psd50", "psd90"],
  sem: [],
  shear_cell: ["cohesion", "FFC"],
  thermal: [
    "tg_onset_dsc",
    "first_melting_point_dsc",
    "second_melting_point_dsc",
    "degradation_temperature_dsc",
    "kf_water_content_tga",
    "mass_loss_tga",
    "evaporation_temperature_tga",
    "degradation_temperature_tga",
  ],
  impurity_and_residual: [
    "test_method",
    "related eln experiments",
    "assay",
    "water",
    "chemical_purities",
    "residual_solvents",
    "heavy_metals",
  ]
};

export const materialInitialData = (material: any) => {
  return {
    page: material?.item ? "update" : "add",
    material_name: material?.material_name ?? "",
    vendor_name: material?.vendor_name ?? "",
    phase: material?.phase ?? "",
    lot_number: material?.lot_number ?? "0000",
    abbreviation: material?.abbreviation ?? "",
    brand_name: material?.brand_name ?? "",
    synonyms: material?.synonyms ?? "",
    boiling_point: material?.boiling_point ?? "",
    cas_number: material?.cas_number ?? "",
    chemical_formula: material?.chemical_formula ?? "",
    incompatibility: material?.incompatibility ?? "",
    shelf_life: material?.shelf_life ?? "",
    melting_point: material?.melting_point ?? "",
    price: material?.price ?? "",
    molecular_weight: material?.molecular_weight ?? "",
    purity: material?.purity ?? "",
    smiles: material?.smiles ?? "",
    manufacturing_number: material?.manufacturing_number ?? "",
    project_name: material?.project_name ?? "",
    process_name: material?.process_name ?? "",
    chemical_safety: material?.chemical_safety ?? "",
    regulatory_filing_data: material?.regulatory_filing_data ?? "",
    impurity_and_residuals: material?.impurity_and_residuals ?? "",
    attachments: {
      general: [],
      process: [],
    },
  };
};

export const solidInitialData = (material: any) => {
  return {
    material_name: material?.material_name ?? "",
    vendor_name: material?.vendor_name ?? "",
    lot_number: material?.lot_number ?? "",
    general: {
      // material_name: material?.material_name ?? '',
      // vendor_name: material?.vendor_name ?? '',
      // lot_number: material?.lot_number ?? '',
      solid_form: material?.solid?.general?.solid_form ?? "",
      type: material?.solid?.general?.type ?? "",
      application: material?.solid?.general?.application ?? "",
      counterion: material?.solid?.general?.counterion ?? "",
      color_a: material?.solid?.general?.color_a ?? "",
      color_b: material?.solid?.general?.color_b ?? "",
      color_l: material?.solid?.general?.color_l ?? "",
      bulk_modulus: material?.solid?.general?.bulk_modulus ?? "",
      heat_of_fusion: material?.solid?.general?.heat_of_fusion ?? "",
      main_impurity_1: material?.solid?.general?.main_impurity_1 ?? "",
      main_impurity_2: material?.solid?.general?.main_impurity_2 ?? "",
      main_impurity_3: material?.solid?.general?.main_impurity_3 ?? "",
      log_s: material?.solid?.general?.log_S ?? material?.solid?.general?.log_s ?? "",
      pKa_1: material?.solid?.general?.pKa_1 ?? "",
      pKa_2: material?.solid?.general?.pKa_2 ?? "",
      pKa_3: material?.solid?.general?.pKa_3 ?? "",
      specific_heat_capacity:
        material?.solid?.general?.specific_heat_capacity ?? "",
      surface_area: material?.solid?.general?.surface_area ?? "",
      surface_energy: material?.solid?.general?.surface_energy ?? "",
      thermal_conductivity:
        material?.solid?.general?.thermal_conductivity ?? "",
      yield_stress: material?.solid?.general?.yield_stress ?? "",
      young_modulus: material?.solid?.general?.young_modulus ?? "",
      form: material?.solid?.general?.form ?? "",
      polymorph: material?.solid?.general?.polymorph ?? "",
      crystal_shape: material?.solid?.general?.crystal_shape ?? "",
      glass_transition_temperature:
        material?.solid?.general?.glass_transition_temperature ?? "",
      // attachment_type: material?.solid?.general?.attachment_type ?? 'COSMO File',
      attachment_type: material?.solid?.general?.attachment_type ?? "",
      attachments: material?.solid?.general?.attachments?.length
        ? parseAttachments(material?.solid?.general?.attachments)
        : [],
    },
    aeration: {
      aeration_ratio: material?.solid?.aeration?.aeration_ratio ?? "",
      aeration_energy: material?.solid?.aeration?.aeration_energy ?? "",
      eln_number: material?.solid?.aeration?.eln_number?.toString() ?? "",
      method_name: material?.solid?.aeration?.method_name?.toString() ?? "",
      regulatory_filing: material?.solid?.aeration?.regulatory_filing ?? "",
      attachments: material?.solid?.aeration?.attachments?.length
        ? parseAttachments(material?.solid?.aeration?.attachments)
        : [],
    },
    compressibility: {
      conditional_bulk_density:
        material?.solid?.compressibility?.conditional_bulk_density ?? "",
      compressibility_value:
        material?.solid?.compressibility?.compressibility_value ?? "",
      density: material?.solid?.compressibility?.density ?? "",
      method_name: material?.solid?.compressibility?.method_name ?? "",
      eln_number: material?.solid?.compressibility?.eln_number ?? "",
      // solid_form: material?.solid?.compressibility?.solid_form ?? '',
      regulatory_filing:
        material?.solid?.compressibility?.regulatory_filing ?? "",
      attachments: material?.solid?.compressibility?.attachments?.length
        ? parseAttachments(material?.solid?.compressibility?.attachments)
        : [],
    },
    densities: {
      bulk_densities: material?.solid?.densities?.bulk_densities ?? "",
      tapped_density: material?.solid?.densities?.tapped_density ?? "",
      eln_number: material?.solid?.densities?.eln_number ?? "",
      // solid_form: material?.solid?.densities?.solid_form ?? '',
      regulatory_filing: material?.solid?.densities?.regulatory_filing ?? "",
      method_number: material?.solid?.densities?.method_number ?? "",
      attachments: {
        sop: material?.solid?.densities?.attachments?.sop ?? [],
      },
    },
    drug_info: {
      dosage_form: material?.solid?.drug_info?.dosage_form ?? "",
      attachments: {
        adme: material?.solid?.drug_info?.attachments?.adme?.length
          ? parseAttachments(material?.solid?.drug_info?.attachments?.adme)
          : [],
        clinical_trials: material?.solid?.drug_info?.attachments
          ?.clinical_trials?.length
          ? parseAttachments(
            material?.solid?.drug_info?.attachments?.clinical_trials
          )
          : [],
        indication: material?.solid?.drug_info?.attachments?.indication?.length
          ? parseAttachments(
            material?.solid?.drug_info?.attachments?.indication
          )
          : [],
        toxicity: material?.solid?.drug_info?.attachments?.toxicity?.length
          ? parseAttachments(material?.solid?.drug_info?.attachments?.toxicity)
          : [],
      },
    },
    flow_energy: {
      basic_flow_energy: material?.solid?.flow_energy?.basic_flow_energy ?? "",
      conditional_bulk_density:
        material?.solid?.flow_energy?.conditional_bulk_density ?? "",
      stability_index: material?.solid?.flow_energy?.stability_index ?? "",
      specific_energy: material?.solid?.flow_energy?.specific_energy ?? "",
      flow_rate_index: material?.solid?.flow_energy?.flow_rate_index ?? "",
      eln_number: material?.solid?.flow_energy?.eln_number ?? "",
      method_name: material?.solid?.flow_energy?.method_name ?? "",
      solid_form: material?.solid?.flow_energy?.solid_form ?? "",
      regulatory_filing: material?.solid?.flow_energy?.regulatory_filing ?? "",
      attachments: material?.solid?.flow_energy?.attachments?.length
        ? parseAttachments(material?.solid?.flow_energy?.attachments)
        : [],
    },
    hygroscopicity: {
      mass_update: material?.solid?.hygroscopicity?.mass_update ?? "",
      category: material?.solid?.hygroscopicity?.category ?? "",
      isotherm_curve: material?.solid?.hygroscopicity?.isotherm_curve ?? "",
      eln_number: material?.solid?.hygroscopicity?.eln_number ?? "",
      method_name: material?.solid?.hygroscopicity?.method_name ?? "",
      solid_form: material?.solid?.hygroscopicity?.solid_form ?? "",
      SOP_file: material?.solid?.hygroscopicity?.SOP_file ?? "",
      regulatory_filing:
        material?.solid?.hygroscopicity?.regulatory_filing ?? "",
      attachments: {
        isothermCurve: material?.solid?.hygroscopicity?.attachments
          ?.isothermCurve?.length
          ? parseAttachments(
            material?.solid?.hygroscopicity?.attachments?.isothermCurve
          )
          : [],
        hygroscopicity2: material?.solid?.hygroscopicity?.attachments
          ?.hygroscopicity2?.length
          ? parseAttachments(
            material?.solid?.hygroscopicity?.attachments?.hygroscopicity2
          )
          : [],
      },
    },
    morphologi: {
      aspect_ratio: material?.solid?.morphologi?.aspect_ratio ?? "",
      aeration_ratio_d50: material?.solid?.morphologi?.aeration_ratio_d50 ?? "",
      aeration_ratio_d90: material?.solid?.morphologi?.aeration_ratio_d90 ?? "",
      eln_number: material?.solid?.morphologi?.eln_number?.toString() ?? "",
      method_name: material?.solid?.morphologi?.method_name?.toString() ?? "",
      solid_form: material?.solid?.morphologi?.solid_form ?? "",
      regulatory_filing: material?.solid?.morphologi?.regulatory_filing ?? "",
      attachments: material?.solid?.morphologi?.attachments?.length
        ? parseAttachments(material?.solid?.morphologi?.attachments)
        : [],
    },
    permeability: {
      conditional_bulk_density:
        material?.solid?.permeability?.conditional_bulk_density ?? "",
      permeability_value:
        material?.solid?.permeability?.permeability_value ?? "",
      density: material?.solid?.permeability?.density ?? "",
      pressure_drop: material?.solid?.permeability?.pressure_drop ?? "",
      eln_number: material?.solid?.permeability?.eln_number?.toString() ?? "",
      method_name: material?.solid?.permeability?.method_name?.toString() ?? "",
      solid_form: material?.solid?.permeability?.solid_form ?? "",
      regulatory_filing: material?.solid?.permeability?.regulatory_filing ?? "",
      attachments: material?.solid?.permeability?.attachments?.length
        ? parseAttachments(material?.solid?.permeability?.attachments)
        : [],
    },
    psd: {
      psd10: material?.solid?.psd?.psd10 ?? "",
      psd50: material?.solid?.psd?.psd50 ?? "",
      psd90: material?.solid?.psd?.psd90 ?? "",
      eln_number: material?.solid?.psd?.eln_number?.toString() ?? "",
      method_name: material?.solid?.psd?.method_name?.toString() ?? "",
      solid_form: material?.solid?.psd?.solid_form ?? "",
      regulatory_filing: material?.solid?.psd?.regulatory_filing ?? "",
      attachments: material?.solid?.psd?.attachments?.length
        ? parseAttachments(material?.solid?.psd?.attachments)
        : [],
    },
    sem: {
      attachments: material?.solid?.sem?.attachments?.length
        ? parseAttachments(material?.solid?.sem?.attachments)
        : [],
    },
    shear_cell: {
      cohesion: material?.solid?.shear_cell?.cohesion ?? "",
      FFC: material?.solid?.shear_cell?.FFC ?? "",
      eln_number: material?.solid?.shear_cell?.eln_number?.toString() ?? "",
      method_name: material?.solid?.shear_cell?.method_name?.toString() ?? "",
      regulatory_filing: material?.solid?.shear_cell?.regulatory_filing ?? "",
      attachments: material?.solid?.shear_cell?.attachments?.length
        ? parseAttachments(material?.solid?.shear_cell?.attachments)
        : [],
    },
    thermal: {
      tg_onset_dsc: material?.solid?.thermal?.tg_onset_dsc ?? "",
      aeration_energy: material?.solid?.thermal?.aeration_energy ?? "",
      first_melting_point_dsc:
        material?.solid?.thermal?.first_melting_point_dsc ?? "",
      second_melting_point_dsc:
        material?.solid?.thermal?.second_melting_point_dsc ?? "",
      degradation_temperature_dsc:
        material?.solid?.thermal?.degradation_temperature_dsc ?? "",
      eln_number_dsc:
        material?.solid?.thermal?.eln_number_dsc?.toString() ?? "",
      method_name_dsc:
        material?.solid?.thermal?.method_name_dsc?.toString() ?? "",
      solid_form_dsc: material?.solid?.thermal?.solid_form_dsc ?? "",
      kf_water_content_tga:
        material?.solid?.thermal?.kf_water_content_tga?.toString() ?? "",
      evaporation_temperature_tga:
        material?.solid?.thermal?.evaporation_temperature_tga ?? "",
      degradation_temperature_tga:
        material?.solid?.thermal?.degradation_temperature_tga ?? "",
      eln_number_tga:
        material?.solid?.thermal?.eln_number_tga?.toString() ?? "",
      method_name_tga:
        material?.solid?.thermal?.method_name_tga?.toString() ?? "",
      solid_form_tga: material?.solid?.thermal?.solid_form_tga ?? "",
      mass_loss_tga: material?.solid?.thermal?.mass_loss_tga ?? "",
      regulatory_filing_dsc:
        material?.solid?.thermal?.regulatory_filing_dsc ?? "",
      regulatory_filing_tga:
        material?.solid?.thermal?.regulatory_filing_tga ?? "",
      SOP_file: material?.solid?.thermal?.SOP_file ?? "",
      attachments: {
        heatCurveDsc: material?.solid?.thermal?.attachments?.heatCurveDsc
          ?.length
          ? parseAttachments(
            material?.solid?.thermal?.attachments?.heatCurveDsc
          )
          : [],
        sopFileDsc: material?.solid?.thermal?.attachments?.sopFileDsc?.length
          ? parseAttachments(material?.solid?.thermal?.attachments?.sopFileDsc)
          : [],
        heatCurveTga: material?.solid?.thermal?.attachments?.heatCurveTga
          ?.length
          ? parseAttachments(
            material?.solid?.thermal?.attachments?.heatCurveTga
          )
          : [],
        sopFileTga: material?.solid?.thermal?.attachments?.sopFileTga?.length
          ? parseAttachments(material?.solid?.thermal?.attachments?.sopFileTga)
          : [],
      },
    },
    impurity_and_residuals: {
      test_method: material?.solid?.impurity_and_residuals?.test_method ?? "",
      related_eln_experiments: material?.solid?.impurity_and_residuals?.related_eln_experiments ?? "",
      assay: material?.solid?.impurity_and_residuals?.assay ?? "",
      water: material?.solid?.impurity_and_residuals?.water ?? "",
      chemical_purities: material?.solid?.impurity_and_residuals?.chemical_purities?.map((e: any) => ({ impurity: e?.impurity, method: e?.method, value: e?.value, unit: e?.unit })) ?? [],
      residual_solvents: material?.solid?.impurity_and_residuals?.residual_solvents?.map((e: any) => ({ solvent: e?.solvent, method: e?.method, value: e?.value, unit: e?.unit, test_method: e?.test_method ,sample_name: e?.sample_name,})) ?? [],
      heavy_metals: material?.solid?.impurity_and_residuals?.heavy_metals?.map((e: any) => ({ metal: e?.metal, method: e?.method, value: e?.value, unit: e?.unit, test_method: e?.test_method , sample_name: e?.sample_name,})) ?? [],
    }
  };
};

export const materialFiltersData = (_formValues: any) => {
  const {
    vendor,
    shelf_life,
    smiles,
    phase,
    phase_options,
    density,
    vapor_density,
    liquid_type,
    flash_point,
    log_p,
    solid_form,
    type,
    log_s,
    form,
    has_project_filter,
  } = _formValues;

  let newFilters = { ..._formValues };

  if (!vendor) {
    newFilters.vendor_name = [];
  }

  if (!has_project_filter) {
    newFilters.project_name = "";
  }

  if (!shelf_life) {
    newFilters.shelf_life_operation = "";
    newFilters.shelf_life_value = "";
  }

  if (!smiles) {
    newFilters.toggle_smiles = false;
  }

  if (!phase) {
    newFilters = {
      ...newFilters,
      phase_options: "",
      ...Object.keys(screenFilterInitialDataByPhase).reduce(
        (e: any, t: string) => {
          e = { ...e, ...screenFilterInitialDataByPhase[t] };
          return e;
        },
        {}
      ),
    };
  }
  if (phase_options?.length) {
    const formFieldsByPhase =
      screenFilterInitialDataByPhase[phase_options.toLowerCase()];
    if (phase_options.toLowerCase() === "gas") {
      newFilters = {
        ...newFilters,
        ...screenFilterInitialDataByPhase.liquid,
        ...screenFilterInitialDataByPhase.solid,
      };
      if (!density) {
        newFilters.density_operation = formFieldsByPhase.density_operation;
        newFilters.density_value = formFieldsByPhase.density_value;
      }
      if (!vapor_density) {
        newFilters.vapor_density_operation =
          formFieldsByPhase.vapor_density_operation;
        newFilters.vapor_density_value = formFieldsByPhase.vapor_density_value;
      }
    }

    if (phase_options.toLowerCase() === "liquid") {
      newFilters = {
        ...newFilters,
        ...screenFilterInitialDataByPhase.gas,
        ...screenFilterInitialDataByPhase.solid,
      };
      if (!liquid_type) {
        newFilters.liquid_type_value = formFieldsByPhase.liquid_type_value;
      }
      if (!flash_point) {
        newFilters.flash_point_operation =
          formFieldsByPhase.flash_point_operation;
        newFilters.flash_point_value = formFieldsByPhase.flash_point_value;
      }
      if (!log_p) {
        newFilters.log_p_operation = formFieldsByPhase.log_p_operation;
        newFilters.log_p_value = formFieldsByPhase.log_p_value;
      }
    }

    if (phase_options.toLowerCase() === "solid") {
      newFilters = {
        ...newFilters,
        ...screenFilterInitialDataByPhase.gas,
        ...screenFilterInitialDataByPhase.liquid,
      };
      if (!solid_form) {
        newFilters.solid_form_value = formFieldsByPhase.solid_form_value;
      }
      if (!type) {
        newFilters.attachment_type = formFieldsByPhase.attachment_type;
      }
      if (!log_s) {
        newFilters.log_s_option = formFieldsByPhase.log_s_option;
        newFilters.log_s_value = formFieldsByPhase.log_s_value;
      }
      if (!form) {
        newFilters.attachments = formFieldsByPhase.attachments;
      }
    }
  }
  return newFilters;
};

export const screenFilterInitialDataByPhase: any = {
  solid: {
    solid_form: false,
    solid_form_value: "",
    type: false,
    attachment_type: "",
    log_s: false,
    log_s_option: "",
    log_s_value: "",
    form: false,
    attachments: "",
  },
  gas: {
    density: false,
    density_operation: "",
    density_value: "",
    vapor_density: false,
    vapor_density_operation: "",
    vapor_density_value: "",
  },
  liquid: {
    liquid_type: false,
    liquid_type_value: "",
    flash_point: false,
    flash_point_operation: "",
    flash_point_value: "",
    log_p: false,
    log_p_operation: "",
    log_p_value: "",
  },
};

export const screeningInitialData = {
  vendor: false,
  has_project_filter: false,
  shelf_life: false,
  smiles: false,
  phase: false,
  solid_form: false,
  type: false,
  log_s: false,
  form: false,
  density: false,
  vapor_density: false,
  liquid_type: false,
  flash_point: false,
  log_p: false,
  vendor_name: [],
  shelf_life_operation: "",
  shelf_life_value: "",
  toggle_smiles: false,
  phase_options: "",
  solid_form_value: "",
  attachment_type: "",
  log_s_option: "",
  log_s_value: "",
  attachments: "",
  density_operation: "",
  density_value: "",
  vapor_density_operation: "",
  vapor_density_value: "",
  liquid_type_value: "",
  flash_point_operation: "",
  flash_point_value: "",
  log_p_operation: "",
  log_p_value: "",
  project_name: "",
};

const getUpdatedFiles = (oldFiles: any[], newFiles: any[]) => {
  return newFiles?.reduce((e: any[], t: any) => {
    let newItem = oldFiles.find(
      (attachment: any) =>
        attachment.category === t.category &&
        attachment.display_name ===
        (t?.file?.name?.toLocaleLowerCase().replaceAll(" ", "-") ||
          t?.display_name)
    );
    if (!newItem) {
      e = [...e, t];
    }
    return e;
  }, []);
};

// export const checkSavedData = (data: any) => {
//   let general = false;
//   let aeration = false;
//   let compressibility = false;
//   let densities = false;
//   let drug_info = false;
//   let flow_energy = false;
//   let hygroscopicity = false;
//   let morphologi = false;
//   let permeability = false;
//   let psd = false;
//   let sem = false;
//   let shear_cell = false;
//   let thermal = false;

//   data?.general && Object.keys(data?.general).length && Object.keys(data?.general).forEach((key: string) => {
//     if (!['attachment_type', 'lot_number', 'material_name', 'vendor_name'].includes(key)) {
//       if (key === 'attachments' && Array.isArray(data?.general[key]) && data?.general[key].length) {
//         general = !0;
//       } else if (typeof data?.general[key] === 'number' && data?.general[key]) {
//         general = !0;
//       } else if (data?.general[key].length) {
//         general = !0;
//       }
//     }
//   });

//   data?.aeration && Object.keys(data?.aeration).length && Object.keys(data?.aeration).forEach((key: string) => {
//     if (key === 'attachments' && Array.isArray(data?.aeration[key]) && data?.aeration[key].length) {
//       aeration = !0;
//     } else if (typeof data?.aeration[key] === 'number' && data?.aeration[key]) {
//       aeration = !0;
//     } else if (data?.aeration[key]?.length) {
//       aeration = !0;
//     }
//   });

//   data?.compressibility && Object.keys(data?.compressibility).length && Object.keys(data?.compressibility).forEach((key: string) => {
//     if (key === 'attachments' && Array.isArray(data?.compressibility[key]) && data?.compressibility[key].length) {
//       compressibility = !0;
//     } else if (typeof data?.compressibility[key] === 'number' && data?.compressibility[key]) {
//       compressibility = !0;
//     } else if (data?.compressibility[key].length) {
//       compressibility = !0;
//     }
//   });

//   data?.densities && Object.keys(data?.densities).length && Object.keys(data?.densities).forEach((key: string) => {
//     if (typeof data?.densities[key] === 'number' && data?.densities[key]) {
//       densities = !0;
//     }
//     else if (data?.densities[key].length) {
//       densities = !0;
//     }
//   });

//   data?.drug_info && Object.keys(data?.drug_info).length && Object.keys(data?.drug_info).forEach((key: string) => {
//     if (key === 'attachments' && Object.keys(data?.drug_info?.attachments).length) {
//       for (var item in data?.drug_info?.attachments) {
//         if (data?.drug_info?.attachments?.[item].length) {
//           drug_info = !0;
//         }
//       }
//     } else if (typeof data?.drug_info[key] === 'number' && data?.drug_info[key]) {
//       drug_info = !0;
//     } else if (data?.drug_info[key]?.length) {
//       drug_info = !0;
//     }
//   });
//   data?.flow_energy && Object.keys(data?.flow_energy).length && Object.keys(data?.flow_energy).forEach((key: string) => {
//     if (key === 'attachments' && Array.isArray(data?.flow_energy[key]) && data?.flow_energy[key].length) {
//       flow_energy = !0;
//     } else if (typeof data?.flow_energy[key] === 'number' && data?.flow_energy[key]) {
//       flow_energy = !0;
//     } else if (data?.flow_energy[key].length) {
//       flow_energy = !0;
//     }
//   });
//   data?.hygroscopicity && Object.keys(data?.hygroscopicity).length && Object.keys(data?.hygroscopicity).forEach((key: string) => {
//     if (key === 'attachments' && Object.keys(data?.hygroscopicity?.attachments).length) {
//       for (var item in data?.hygroscopicity?.attachments) {
//         if (data?.hygroscopicity?.attachments?.[item].length) {
//           hygroscopicity = !0;
//         }
//       }
//     } else if (typeof data?.hygroscopicity[key] === 'number' && data?.hygroscopicity[key]) {
//       hygroscopicity = !0;
//     } else if (data?.hygroscopicity[key]?.length) {
//       hygroscopicity = !0;
//     }
//   });

//   data?.morphologi && Object.keys(data?.morphologi).length && Object.keys(data?.morphologi).forEach((key: string) => {
//     if (key === 'attachments' && Array.isArray(data?.morphologi[key]) && data?.morphologi[key].length) {
//       morphologi = !0;
//     } else if (typeof data?.morphologi[key] === 'number' && data?.morphologi[key]) {
//       morphologi = !0;
//     } else if (data?.morphologi[key].length) {
//       morphologi = !0;
//     }
//   });

//   data?.permeability && Object.keys(data?.permeability).length && Object.keys(data?.permeability).forEach((key: string) => {
//     if (key === 'attachments' && Array.isArray(data?.permeability[key]) && data?.permeability[key].length) {
//       permeability = !0;
//     } else if (typeof data?.permeability[key] === 'number' && data?.permeability[key]) {
//       permeability = !0;
//     } else if (data?.permeability[key].length) {
//       permeability = !0;
//     }
//   });

//   data?.psd && Object.keys(data?.psd).length && Object.keys(data?.psd).forEach((key: string) => {
//     if (key === 'attachments' && Array.isArray(data?.psd[key]) && data?.psd[key].length) {
//       psd = !0;
//     } else if (typeof data?.psd[key] === 'number' && data?.psd[key]) {
//       psd = !0;
//     } else if (data?.psd[key].length) {
//       psd = !0;
//     }
//   });

//   data?.sem && Object.keys(data?.sem).length && Object.keys(data?.sem).forEach((key: string) => {
//     if (key === 'attachments' && Array.isArray(data?.sem[key]) && data?.sem[key].length) {
//       sem = !0;
//     } else if (typeof data?.sem[key] === 'number' && data?.sem[key]) {
//       sem = !0;
//     } else if (data?.sem[key].length) {
//       sem = !0;
//     }
//   });

//   data?.shear_cell && Object.keys(data?.shear_cell).length && Object.keys(data?.shear_cell).forEach((key: string) => {
//     if (key === 'attachments' && Array.isArray(data?.shear_cell[key]) && data?.shear_cell[key].length) {
//       shear_cell = !0;
//     } else if (typeof data?.shear_cell[key] === 'number' && data?.shear_cell[key]) {
//       shear_cell = !0;
//     } else if (data?.shear_cell[key].length) {
//       shear_cell = !0;
//     }
//   });

//   data?.thermal && Object.keys(data?.thermal).length && Object.keys(data?.thermal).forEach((key: string) => {
//     if (key === 'attachments' && Object.keys(data?.thermal?.attachments).length) {
//       for (var item in data?.thermal?.attachments) {
//         if (data?.thermal?.attachments?.[item].length) {
//           thermal = !0;
//         }
//       }
//     } else if (typeof data?.thermal[key] === 'number' && data?.thermal[key]) {
//       thermal = !0;
//     } else if (data?.thermal[key].length) {
//       thermal = !0;
//     }
//   });

//   return {
//     general,
//     aeration,
//     compressibility,
//     densities,
//     drug_info,
//     flow_energy,
//     hygroscopicity,
//     morphologi,
//     permeability,
//     psd,
//     sem,
//     shear_cell,
//     thermal,
//   }
// }

export const getAttachments = (solid: any, data: any) => {
  return Object.values([
    ...getUpdatedFiles(
      solid?.general?.attachments ?? [],
      data?.general?.attachments ?? []
    ),
    ...getUpdatedFiles(
      solid?.aeration?.attachments ?? [],
      data?.aeration?.attachments ?? []
    ),
    ...getUpdatedFiles(
      solid?.compressibility?.attachments ?? [],
      data?.compressibility?.attachments ?? []
    ),
    ...getUpdatedFiles(
      Object.values(solid?.densities?.attachments ?? []).reduce(
        (e: any[], t: any) => {
          e = [...e, ...t];
          return e;
        },
        []
      ),
      Object.values(data?.densities?.attachments ?? []).reduce(
        (e: any[], t: any) => {
          e = [...e, ...t];
          return e;
        },
        []
      )
    ),
    ...getUpdatedFiles(
      Object.values(solid?.drug_info?.attachments ?? []).reduce(
        (e: any[], t: any) => {
          e = [...e, ...t];
          return e;
        },
        []
      ),
      Object.values(data?.drug_info?.attachments ?? []).reduce(
        (e: any[], t: any) => {
          e = [...e, ...t];
          return e;
        },
        []
      )
    ),
    ...getUpdatedFiles(
      solid?.flow_energy?.attachments ?? [],
      data?.flow_energy?.attachments ?? []
    ),
    ...getUpdatedFiles(
      Object.values(solid?.hygroscopicity?.attachments ?? []).reduce(
        (e: any[], t: any) => {
          e = [...e, ...t];
          return e;
        },
        []
      ),
      Object.values(data?.hygroscopicity?.attachments ?? []).reduce(
        (e: any[], t: any) => {
          e = [...e, ...t];
          return e;
        },
        []
      )
    ),
    ...getUpdatedFiles(
      solid?.morphologi?.attachments ?? [],
      data?.morphologi?.attachments ?? []
    ),
    ...getUpdatedFiles(
      solid?.permeability?.attachments ?? [],
      data?.permeability?.attachments ?? []
    ),
    ...getUpdatedFiles(
      solid?.psd?.attachments ?? [],
      data?.psd?.attachments ?? []
    ),
    ...getUpdatedFiles(
      solid?.sem?.attachments ?? [],
      data?.sem?.attachments ?? []
    ),
    ...getUpdatedFiles(
      solid?.shear_cell?.attachments ?? [],
      data?.shear_cell?.attachments ?? []
    ),
    ...getUpdatedFiles(
      Object.values(solid?.thermal?.attachments ?? []).reduce(
        (e: any[], t: any) => {
          e = [...e, ...t];
          return e;
        },
        []
      ),
      Object.values(data?.thermal?.attachments ?? []).reduce(
        (e: any[], t: any) => {
          e = [...e, ...t];
          return e;
        },
        []
      )
    ),
  ]);
};

export const initialMaterialData = (material: any) => {
  return {
    material_name: material?.material_name ?? null,
    vendor_name: material?.vendor_name ?? null,
    phase: material?.phase ?? null,
    lot_number: material?.lot_number ?? null,
    abbreviation: material?.abbreviation ?? null,
    brand_name: material?.brand_name ?? null,
    synonyms: material?.synonyms ?? null,
    boiling_point: material?.boiling_point ?? null,
    cas_number: material?.cas_number ?? null,
    chemical_formula: material?.chemical_formula ?? null,
    incompatibility: material?.incompatibility ?? null,
    shelf_life: material?.shelf_life ?? null,
    melting_point: material?.melting_point ?? null,
    price: material?.price ?? null,
    molecular_weight: material?.molecular_weight ?? null,
    purity: material?.purity ?? null,
    smiles: material?.smiles ?? null,
    manufacturing_number: material?.manufacturing_number ?? null,
    project_name: material?.project_name ?? null,
    process_name: material?.process_name ?? null,
    chemical_safety: material?.chemical_safety ?? null,
    regulatory_filing_data: material?.regulatory_filing_data ?? null,
  };
};

export const solubilityInitialData = (material: any) => {
  return {
    material_name: material?.material_name ?? null,
    vendor_name: material?.vendor_name ?? null,
    lot_number: material?.lot_number ?? null,
    main_solvent: material?.solubility?.main_solvent?.toString() ?? null,
    solubility_in_main_solvent:
      material?.solubility?.solubility_in_main_solvent?.toString() ?? null,
    main_solvent_temperature:
      material?.solubility?.main_solvent_temperature?.toString() ?? null,
    main_solvent_reference:
      material?.solubility?.main_solvent_reference?.toString() ?? null,
    second_solvent: material?.solubility?.second_solvent?.toString() ?? null,
    solubility_in_second_solvent:
      material?.solubility?.solubility_in_second_solvent?.toString() ?? null,
    second_solvent_temperature:
      material?.solubility?.second_solvent_temperature?.toString() ?? null,
    second_solvent_reference:
      material?.solubility?.second_solvent_reference?.toString() ?? null,
    attachments: material?.solubility?.attachments
      ? parseAttachments(material?.solubility?.attachments)
      : [],
  };
};

export const spectralIformationInitialData = (material: any) => {
  return {
    material_name: material?.material_name ?? "",
    vendor_name: material?.vendor_name ?? "",
    lot_number: material?.lot_number ?? "",
    attachments: {
      atrIR: material?.spectralInfo?.attachments?.atrIR
        ? parseAttachments(material?.spectralInfo?.attachments?.atrIR)
        : [],
      gcMS: material?.spectralInfo?.attachments?.gcMS
        ? parseAttachments(material?.spectralInfo?.attachments?.gcMS)
        : [],
      ir: material?.spectralInfo?.attachments?.ir
        ? parseAttachments(material?.spectralInfo?.attachments?.ir)
        : [],
      lcMS: material?.spectralInfo?.attachments?.lcMS
        ? parseAttachments(material?.spectralInfo?.attachments?.lcMS)
        : [],
      msMS: material?.spectralInfo?.attachments?.msMS
        ? parseAttachments(material?.spectralInfo?.attachments?.msMS)
        : [],
      nmr: material?.spectralInfo?.attachments?.nmr
        ? parseAttachments(material?.spectralInfo?.attachments?.nmr)
        : [],
      raman: material?.spectralInfo?.attachments?.raman
        ? parseAttachments(material?.spectralInfo?.attachments?.raman)
        : [],
    },
  };
};

export const parseSpectralFiles = (attachments: any) => {
  return Object.keys(attachments).reduce(
    (e: any, t: string) => {
      const attachmentsByCategory = attachments[t];
      e[t] = attachmentsByCategory.map((attachmentByCategory: any) => {
        const data: any = {};
        data.category = attachmentByCategory.category;
        data.error = false;
        data.isUpload = true;
        data.file = {
          name: attachmentByCategory.display_name,
          type: attachmentByCategory.mime_type,
        };
        return data;
      });
      return e;
    },
    {
      atrIR: [],
      gcMS: [],
      ir: [],
      lcMS: [],
      msMS: [],
      nmr: [],
      raman: [],
    }
  );
};

export const getAllSpectralInfoFiles = (attachments: any) => {
  return Object.keys(attachments).reduce((e: any[], t: string) => {
    e = [...e, ...attachments[t]];
    return e;
  }, []);
};

export const environmentInitialData = (material: any) => {
  return {
    material_name: material?.material_name ?? null,
    vendor_name: material?.vendor_name ?? null,
    lot_number: material?.lot_number ?? null,
    metric1: material?.environment?.metric1 ?? null,
    metric2: material?.environment?.metric2 ?? null,
    metric3: material?.environment?.metric3 ?? null,
    metric4: material?.environment?.metric4 ?? null,
    XXReport: material?.environment?.XXReport ?? null,
  };
};

export const materialSolubilityInitialData = (material: any) => {
  return {
    attachments: {
      unchainedLabData:
        material?.solubility?.attachments?.unchainedLabData ?? [],
      hplcData: material?.solubility?.attachments?.hplcData ?? [],
      crystal16Data: material?.solubility?.attachments?.crystal16Data ?? [],
      crystallineData: material?.solubility?.attachments?.crystallineData ?? [],
      gravimetryMethod:
        material?.solubility?.attachments?.gravimetryMethod ?? [],
      solubilityCurve1:
        material?.solubility?.attachments?.solubilityCurve1 ?? [],
      solubilityCurve2:
        material?.solubility?.attachments?.solubilityCurve2 ?? [],
      solubilityCurve3:
        material?.solubility?.attachments?.solubilityCurve3 ?? [],
      solubilityCurve4:
        material?.solubility?.attachments?.solubilityCurve4 ?? [],
      solubilityCurve5:
        material?.solubility?.attachments?.solubilityCurve5 ?? [],
    },
    models: material?.solubility?.models ?? "",
    solvent_1: material?.solubility?.solvent_1 ?? "",
    solvent_2: material?.solubility?.solvent_2 ?? "",
    temperature: material?.solubility?.temperature ?? "",
    solubility_value: material?.solubility?.solubility_value ?? "",
    plot: material?.solubility?.plot ?? false,
  };
};

export const gasInitialData = (material: any) => {
  return {
    material_name: material?.material_name ?? "",
    vendor_name: material?.vendor_name ?? "",
    lot_number: material?.lot_number ?? "",
    gas: {
      application: material?.gas?.application?.toString() ?? "",
      boiling_point: material?.gas?.boiling_point?.toString() ?? "",
      density: material?.gas?.density?.toString() ?? "",
      heat_of_vaporization:
        material?.gas?.heat_of_vaporization?.toString() ?? "",
      vapor_density: material?.gas?.vapor_density?.toString() ?? "",
      viscosity: material?.gas?.viscosity?.toString() ?? "",
    },
  };
};

export const liquidInitialData = (material: any) => {
  return {
    material_name: material?.material_name ?? "",
    vendor_name: material?.vendor_name ?? "",
    lot_number: material?.lot_number ?? "",
    liquid: {
      autoignition_temperature:
        material?.liquid?.autoignition_temperature?.toString() ?? "",
      type: material?.liquid?.type?.toString() ?? "",
      color_l: material?.liquid?.color_l?.toString() ?? "",
      color_a: material?.liquid?.color_a?.toString() ?? "",
      color_b: material?.liquid?.color_b?.toString() ?? "",
      corrosivity: material?.liquid?.corrosivity?.toString() ?? "",
      density: material?.liquid?.density?.toString() ?? "",
      flash_point: material?.liquid?.flash_point?.toString() ?? "",
      heat_of_combustion:
        material?.liquid?.heat_of_combustion?.toString() ?? "",
      heat_of_vaporization:
        material?.liquid?.heat_of_vaporization?.toString() ?? "",
      henry_law_constant:
        material?.liquid?.henry_law_constant?.toString() ?? "",
      ionization_potential:
        material?.liquid?.ionization_potential?.toString() ?? "",
      log_p: material?.liquid?.log_p?.toString() ?? "",
      pKa: material?.liquid?.pKa?.toString() ?? "",
      refractive_index: material?.liquid?.refractive_index?.toString() ?? "",
      relative_evaporation_rate:
        material?.liquid?.relative_evaporation_rate?.toString() ?? "",
      specific_heat_capacity:
        material?.liquid?.specific_heat_capacity?.toString() ?? "",
      surface_tension: material?.liquid?.surface_tension?.toString() ?? "",
      vapor_density: material?.liquid?.vapor_density?.toString() ?? "",
      vapor_pressure: material?.liquid?.vapor_pressure?.toString() ?? "",
      viscosity: material?.liquid?.viscosity?.toString() ?? "",
      volatility: material?.liquid?.volatility?.toString() ?? "",
    },
  };
};

export const generalSolidInitialData = (material: any) => {
  return {
    material_name: material?.material_name ?? "",
    vendor_name: material?.vendor_name ?? "",
    lot_number: material?.lot_number ?? "",
    solid_form: material?.solid?.general?.solid_form ?? "",
    type: material?.solid?.general?.type ?? "",
    application: material?.solid?.general?.application ?? "",
    counterion: material?.solid?.general?.counterion ?? "",
    color_l: material?.solid?.general?.color_l ?? "",
    color_a: material?.solid?.general?.color_a ?? "",
    color_b: material?.solid?.general?.color_b ?? "",
    bulk_modulus: material?.solid?.general?.bulk_modulus ?? "",
    heat_of_fusion: material?.solid?.general?.heat_of_fusion ?? "",
    main_impurity_1: material?.solid?.general?.main_impurity_1 ?? "",
    main_impurity_2: material?.solid?.general?.main_impurity_2 ?? "",
    main_impurity_3: material?.solid?.general?.main_impurity_3 ?? "",
    log_s: material?.solid?.general?.log_s ?? "",
    pKa_1: material?.solid?.general?.pKa_1 ?? "",
    pKa_2: material?.solid?.general?.pKa_2 ?? "",
    pKa_3: material?.solid?.general?.pKa_3 ?? "",
    specific_heat_capacity:
      material?.solid?.general?.specific_heat_capacity ?? "",
    surface_area: material?.solid?.general?.surface_area ?? "",
    surface_energy: material?.solid?.general?.surface_energy ?? "",
    thermal_conductivity: material?.solid?.general?.thermal_conductivity ?? "",
    yield_stress: material?.solid?.general?.yield_stress ?? "",
    young_modulus: material?.solid?.general?.young_modulus ?? "",
    form: material?.solid?.general?.form ?? "",
    polymorph: material?.solid?.general?.polymorph ?? "",
    crystal_shape: material?.solid?.general?.crystal_shape ?? "",
    glass_transition_temperature:
      material?.solid?.general?.glass_transition_temperature ?? "",
    attachments: material?.solid?.general?.attachments ?? [],
  };
};

export const densityInitialData = (material: any) => {
  return {
    bulk_densities: material?.solid?.densities?.bulk_densities ?? "",
    tapped_density: material?.solid?.densities?.tapped_density ?? "",
    eln_number: material?.solid?.densities?.eln_number ?? "",
    solid_form: material?.solid?.densities?.solid_form ?? "",
    regulatory_filing: material?.solid?.densities?.regulatory_filing ?? "",
    method_number: material?.solid?.densities?.method_number ?? "",
    attachments: {
      sop: material?.solid?.densities?.attachments?.sop ?? [],
    },
  };
};

export const compressibilityInitialData = (material: any) => {
  return {
    conditional_bulk_density:
      material?.solid?.compressibility?.conditional_bulk_density ?? "",
    compressibility_value:
      material?.solid?.compressibility?.compressibility_value ?? "",
    density: material?.solid?.compressibility?.density ?? "",
    method_name: material?.solid?.compressibility?.method_name ?? "",
    eln_number: material?.solid?.compressibility?.eln_number ?? "",
    solid_form: material?.solid?.compressibility?.solid_form ?? "",
    regulatory_filing:
      material?.solid?.compressibility?.regulatory_filing ?? "",
    SOP_file: material?.solid?.compressibility?.SOP_file ?? "",
    attachments: material?.solid?.compressibility?.attachments ?? [],
  };
};
export const permeabilityInitialData = (material: any) => {
  return {
    conditional_bulk_density:
      material?.solid?.permeability?.conditional_bulk_density ?? "",
    permeability_value: material?.solid?.permeability?.permeability_value ?? "",
    density: material?.solid?.permeability?.density ?? "",
    pressure_drop: material?.solid?.permeability?.pressure_drop ?? "",
    eln_number: material?.solid?.permeability?.eln_number ?? "",
    method_name: material?.solid?.permeability?.method_name ?? "",
    solid_form: material?.solid?.permeability?.solid_form ?? "",
    regulatory_filing: material?.solid?.permeability?.regulatory_filing ?? "",
    SOP_file: material?.solid?.permeability?.SOP_file ?? "",
    attachments: material?.solid?.permeability?.attachments ?? [],
  };
};
export const shearCellInitialData = (material: any) => {
  return {
    cohesion: material?.solid?.shear_cell?.cohesion ?? "",
    FFC: material?.solid?.shear_cell?.FFC ?? "",
    eln_number: material?.solid?.shear_cell?.eln_number ?? "",
    method_name: material?.solid?.shear_cell?.method_name ?? "",
    solid_form: material?.solid?.shear_cell?.solid_form ?? "",
    regulatory_filing: material?.solid?.shear_cell?.regulatory_filing ?? "",
    SOP_file: material?.solid?.shear_cell?.SOP_file ?? "",
    attachments: material?.solid?.shear_cell?.attachments ?? [],
  };
};
export const flowEnergyInitialData = (material: any) => {
  return {
    basic_flow_energy: material?.solid?.flow_energy?.basic_flow_energy ?? "",
    conditional_bulk_density:
      material?.solid?.flow_energy?.conditional_bulk_density ?? "",
    stability_index: material?.solid?.flow_energy?.stability_index ?? "",
    specific_energy: material?.solid?.flow_energy?.specific_energy ?? "",
    flow_rate_index: material?.solid?.flow_energy?.flow_rate_index ?? "",
    eln_number: material?.solid?.flow_energy?.eln_number ?? "",
    regulatory_filing: material?.solid?.flow_energy?.regulatory_filing ?? "",
    method_name: material?.solid?.flow_energy?.method_name ?? "",
    solid_form: material?.solid?.flow_energy?.solid_form ?? "",
    SOP_file: material?.solid?.flow_energy?.SOP_file ?? "",
    attachments: material?.solid?.flow_energy?.attachments ?? [],
  };
};

export const impurityAndResidualsInitialData = (material: any) => {
  return {
    test_method: material?.solid?.impurity_and_residuals?.test_method ?? "",
    related_eln_experiments: material?.solid?.impurity_and_residuals?.related_eln_experiments ?? "",
    assay: material?.solid?.impurity_and_residuals?.assay ?? "",
    water: material?.solid?.impurity_and_residuals?.water ?? "",
    chemical_purities:
      material?.solid?.impurity_and_residuals?.chemical_purities?.map((e: any) => ({
        impurity: e?.impurity,
        method: e?.method,
        value: e?.value,
        unit: e?.unit,
      })) ?? [],
    residual_solvents:
      material?.solid?.impurity_and_residuals?.residual_solvents?.map((e: any) => ({
        solvent: e?.solvent,
        method: e?.method,
        value: e?.value,
        unit: e?.unit,
        test_method: e?.test_method,
        sample_name: e?.sample_name,
      })) ?? [],
    heavy_metals:
      material?.solid?.impurity_and_residuals?.heavy_metals?.map((e: any) => ({
        metal: e?.metal,
        method: e?.method,
        value: e?.value,
        unit: e?.unit,
        test_method: e?.test_method,
        sample_name: e?.sample_name,
      })) ?? [],
  };
};


export const aerationInitialData = (material: any) => {
  return {
    aeration_ratio: material?.solid?.aeration?.aeration_ratio ?? "",
    aeration_energy: material?.solid?.aeration?.aeration_energy ?? "",
    eln_number: material?.solid?.aeration?.eln_number ?? "",
    method_name: material?.solid?.aeration?.method_name ?? "",
    solid_form: material?.solid?.aeration?.solid_form ?? "",
    regulatory_filing: material?.solid?.aeration?.regulatory_filing ?? "",
    SOP_file: material?.solid?.aeration?.SOP_file ?? "",
    attachments: material?.solid?.aeration?.attachments ?? [],
  };
};
export const thermalInitialData = (material: any) => {
  return {
    tg_onset_dsc: material?.solid?.thermal?.tg_onset_dsc ?? "",
    aeration_energy: material?.solid?.thermal?.aeration_energy ?? "",
    first_melting_point_dsc:
      material?.solid?.thermal?.first_melting_point_dsc ?? "",
    second_melting_point_dsc:
      material?.solid?.thermal?.second_melting_point_dsc ?? "",
    degradation_temperature_dsc:
      material?.solid?.thermal?.degradation_temperature_dsc ?? "",
    eln_number_dsc: material?.solid?.thermal?.eln_number_dsc ?? "",
    method_name_dsc: material?.solid?.thermal?.method_name_dsc ?? "",
    solid_form_dsc: material?.solid?.thermal?.solid_form_dsc ?? "",
    heat_curve_dsc: material?.solid?.thermal?.heat_curve_dsc ?? "",
    SOP_file_dsc: material?.solid?.thermal?.SOP_file_dsc ?? "",
    regulatory_filing_dsc:
      material?.solid?.thermal?.regulatory_filing_dsc ?? "",
    kf_water_content_tga: material?.solid?.thermal?.kf_water_content_tga ?? "",
    mass_loss_tga: material?.solid?.thermal?.mass_loss_tga ?? "",
    evaporation_temperature_tga:
      material?.solid?.thermal?.evaporation_temperature_tga ?? "",
    degradation_temperature_tga:
      material?.solid?.thermal?.degradation_temperature_tga ?? "",
    eln_number_tga: material?.solid?.thermal?.eln_number_tga ?? "",
    regulatory_filing_tga:
      material?.solid?.thermal?.regulatory_filing_tga ?? "",
    method_name_tga: material?.solid?.thermal?.method_name_tga ?? "",
    SOP_file_tga: material?.solid?.thermal?.SOP_file_tga ?? "",
    attachments: {
      heatCurveDsc: material?.solid?.thermal?.attachments?.heatCurveDsc ?? [],
      sopFileDsc: material?.solid?.thermal?.attachments?.sopFileDsc ?? [],
      heatCurveTga: material?.solid?.thermal?.attachments?.heatCurveTga ?? [],
      sopFileTga: material?.solid?.thermal?.attachments?.sopFileTga ?? [],
    },
  };
};
export const hygroscopicityInitialData = (material: any) => {
  return {
    mass_update: material?.solid?.hygroscopicity?.mass_update ?? "",
    category: material?.solid?.hygroscopicity?.category ?? "",
    isotherm_curve: material?.solid?.hygroscopicity?.isotherm_curve ?? "",
    eln_number: material?.solid?.hygroscopicity?.eln_number ?? "",
    method_name: material?.solid?.hygroscopicity?.method_name ?? "",
    solid_form: material?.solid?.hygroscopicity?.solid_form ?? "",
    regulatory_filing: material?.solid?.hygroscopicity?.regulatory_filing ?? "",
    SOP_file: material?.solid?.hygroscopicity?.SOP_file ?? "",
    attachments: {
      isothermCurve:
        material?.solid?.hygroscopicity?.attachments?.isothermCurve ?? [],
      hygroscopicity2:
        material?.solid?.hygroscopicity?.attachments?.hygroscopicity2 ?? [],
    },
  };
};
export const psdInitialData = (material: any) => {
  return {
    psd10: material?.solid?.psd?.psd10 ?? "",
    psd50: material?.solid?.psd?.psd50 ?? "",
    psd90: material?.solid?.psd?.psd90 ?? "",
    eln_number: material?.solid?.psd?.eln_number ?? "",
    method_name: material?.solid?.psd?.method_name ?? "",
    solid_form: material?.solid?.psd?.solid_form ?? "",
    regulatory_filing: material?.solid?.psd?.regulatory_filing ?? "",
    SOP_file: material?.solid?.psd?.SOP_file ?? "",
    attachments: material?.solid?.psd?.attachments ?? [],
  };
};
export const semInitialData = (material: any) => {
  return {
    attachments: material?.solid?.sem?.attachments ?? [],
  };
};
export const morphologiInitialData = (material: any) => {
  return {
    aspect_ratio: material?.solid?.morphologi?.aspect_ratio ?? "",
    aeration_ratio_d50: material?.solid?.morphologi?.aeration_ratio_d50 ?? "",
    aeration_ratio_d90: material?.solid?.morphologi?.aeration_ratio_d90 ?? "",
    eln_number: material?.solid?.morphologi?.eln_number ?? "",
    method_name: material?.solid?.morphologi?.method_name ?? "",
    solid_form: material?.solid?.morphologi?.solid_form ?? "",
    regulatory_filing: material?.solid?.morphologi?.regulatory_filing ?? "",
    SOP_file: material?.solid?.morphologi?.SOP_file ?? "",
    attachments: material?.solid?.morphologi?.attachments ?? [],
  };
};
export const drugInfoInitialData = (material: any) => {
  return {
    dosage_form: material?.solid?.drug_info?.dosage_form ?? "",
    attachments: {
      adme: material?.solid?.drug_info?.attachments?.adme ?? [],
      clinical_trials:
        material?.solid?.drug_info?.attachments?.clinical_trials ?? [],
      indication: material?.solid?.drug_info?.attachments?.indication ?? [],
      toxicity: material?.solid?.drug_info?.attachments?.toxicity ?? [],
    },
  };
};

export const getSolubilityFileFields = (simulation: any) => {
  return Object.keys(simulation).reduce((e: any, t: string) => {
    if (Array.isArray(simulation[t])) {
      e = [...e, ...simulation[t]];
      return e;
    }

    return e;
  }, []);
};

export const formatMaterialFiles = (attachments: any[]) => {
  return attachments.reduce((e: any, t: any) => {
    e[t.category] = e[t.category] || [];
    e[t.category].push(t);
    return e;
  }, {});
};

const getFileNames = async (items: any): Promise<any> => {
  let results: any = Object.keys(items);

  results = results.reduce(async (e: any, t: string) => {
    const valueArr: any = await await Promise.all(
      items[t].map(async (item: any) => await cleanFileName(item.file.name))
    );
    e = {
      ...e,
      ...{
        category: t,
        items: valueArr,
        isDuplicate: valueArr.some((item: any, index: number) => {
          return valueArr.indexOf(item) != index;
        }),
      },
    };
    return e;
  }, {});
  return results;
};

export const checkDuplicateFiles = async (attachments: any[]) => {
  try {
    const newAttachments = attachments.map((attachment) => {
      if (!attachment.isUpload) {
        // clean file name for new file.
        attachment = {
          ...attachment,
          file: {
            ...attachment.file,
            name: cleanFileName(attachment?.file?.name),
          },
        };
        return attachment;
      }
      return attachment;
    });
    // categorized files
    const attachmentsByCategory = formatMaterialFiles(newAttachments);
    // check duplicate files
    return Object.keys(attachmentsByCategory).reduce((e: any, t: string) => {
      const valueArr: string[] = attachmentsByCategory[t].map(
        (item: any) => item?.file?.name
      );
      e = [
        ...e,
        ...[
          {
            category: t,
            items: attachmentsByCategory[t],
            isDuplicate: valueArr.some((item: any, index: number) => {
              return valueArr.indexOf(item) != index;
            }),
          },
        ],
      ];
      return e;
    }, []);
  } catch (error) {
    console.error("An error occurred:", error);
    return [];
  }
  // return Promise.all([getFileNames(attachmentsByCategory)]);
  // return Object.keys(attachmentsByCategory).map((key: string) => {
  //   var valueArr = attachmentsByCategory[key].map(async (item: any) => await cleanFileName(item.file.name));
  //   return {
  //     category: key,
  //     items: valueArr,
  //     isDuplicate: valueArr.some((item: any, index: number) => {
  //       return valueArr.indexOf(item) != index;
  //     }),
  //   }
  // });
};

export const sanitizeObj = async function (obj: any) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      if (
        value === "" ||
        value === null ||
        value === undefined ||
        Number.isNaN(value) ||
        (Array.isArray(value) && value.length === 0) ||
        (typeof value === "object" && Object.keys(value).length === 0)
      ) {
        delete obj[key];
      }
      if (typeof value === "object" && !Array.isArray(value)) {
        await sanitizeObj(value);
      }

      if (
        typeof obj === "object" &&
        !Array.isArray(obj) &&
        Object.keys(value).length === 0
      ) {
        delete obj[key];
      }
    }
  }
  return obj;
};

export const isObjectEmpty = async (obj: any) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];

      if (typeof value === "string" && value?.length) {
        // Found non-empty value
        return false;
      }
      if (typeof value === "object" && Array.isArray(value) && value.length) {
        // Found non-empty value
        return false;
      }
      if (
        typeof value === "object" &&
        !Array.isArray(value) &&
        !(await isObjectEmpty(value))
      ) {
        // Recursively check nested objects
        return false;
      }
    }
  }
  return true; // All keys checked and no non-empty values found
};

export const isDataChanged = async function (dbM: any, formM: any) {
  let dbMaterial = Object.assign({}, dbM);
  let formMaterial = Object.assign({}, formM);
  delete dbMaterial.id;
  delete dbMaterial.uid;
  delete dbMaterial.sort_key;
  delete dbMaterial.partition_key;
  delete dbMaterial.page;
  formMaterial.phase = dbMaterial.phase;
  const db_material = await sanitizeObj(dbMaterial);
  const form_material = await sanitizeObj(formMaterial);
  const fm = await removeEmpty(form_material);
  return !areObjectsEqual(db_material, fm);
  // return JSON.stringify(db_material) !== JSON.stringify(fm);
};

export async function removeEmpty(obj: any): Promise<any> {
  if (obj && typeof obj === "object") {
    if (Array.isArray(obj)) {
      // Remove empty elements from arrays
      const filteredArr = await Promise.all(
        obj.map(async (item: any) => {
          if (typeof item === "object") {
            return await removeEmpty(item);
          }
          return item;
        })
      );
      return filteredArr.filter((item) => !isEmpty(item));
    } else {
      // Remove empty keys from objects
      const result: any = {};
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const value = await removeEmpty(obj[key]);
          if (!isEmpty(value)) {
            result[key] = value;
          }
        }
      }
      return result;
    }
  } else {
    // Remove null, undefined, NaN, and empty strings
    return obj;
  }
}

function isEmpty(value: any): boolean {
  return (
    value === null ||
    value === undefined ||
    (typeof value === "number" && isNaN(value)) ||
    (typeof value === "string" && value.trim() === "") ||
    (Array.isArray(value) && value.length === 0) ||
    (typeof value === "object" && Object.keys(value).length === 0)
  );
}

function areObjectsEqual(obj1: any, obj2: any): boolean {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  // Check if number of keys match
  if (keys1.length !== keys2.length) {
    return false;
  }
  // Check if all keys and their values match
  for (const key of keys1) {
    const val1 = obj1[key];
    const val2 = obj2[key];
    // Check for nested objects recursively
    if (typeof val1 === "object" && typeof val2 === "object") {
      if (!areObjectsEqual(val1, val2)) {
        return false;
      }
    } else if (val1 !== val2) {
      return false;
    }
  }
  return true;
}

// LEAVE THESE FOR FUTURE USE
// const gasInitialData = gasGeneralFields.reduce((e: any, t: any) => {
//     e[t.value] = false;
//     return e;
// }, {});

// const liquidInitialData = liquidGeneralFields.reduce((e: any, t: any) => {
//     e[t.value] = false;
//     return e;
// }, {});

// const initialData = {
//     liquid: liquidInitialData,
//     gas: gasInitialData,
// }

export const materialPhaseTypes = [
  { label: "Gas", value: "Gas" },
  { label: "Liquid", value: "Liquid" },
  { label: "Solid", value: "Solid" },
];

export const chemicalSafetyOptions = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
];

export const regulatoryFilingOptions = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
];

export const materialSolubilityModelOptions = [
  { label: "Model 1", value: "Model 1" },
  {
    label: "SMILES Solubility Predictor",
    value: "CMAC",
    description:
      "This model predicts the solubility of single solvent systems from the solute and solvent SMILES strings by using molecular descriptors, providing an estimation useful in early development stages.",
  },
  {
    label: "Solubility Image Predictor",
    value: "Model 3",
    description:
      "This CNN model, trained on images obtained from high-throughput experiments, predicts the solubility of single solvent systems within vials, aiding solvent selection decision.",
  },
  {
    label: "Solubility Dynamics Model",
    value: "ETC",
    description:
      "This model predicts solubility for single solvent systems, using dynamics of incorporation events at discrete interfaces, and is trained and validated on a diverse set of solubility measurements.",
  },
];

export const isEmptyObject = (obj: any): boolean => {
  if (obj == null) return true; // Handle null or undefined
  if (typeof obj !== 'object') return false; // If it's a primitive value, it's not empty
  
  if (Array.isArray(obj)) {
    // If it's an array, check if all elements are empty
    return obj.length === 0 || obj.every(item => isEmptyObject(item));
  }

  // If it's an object, check if any key has a non-empty value
  return Object.keys(obj).length === 0 || Object.values(obj).every(value => isEmptyObject(value));
};