import { Modal, Button, Icon } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import { Bar, Line } from "react-chartjs-2"; // Import Line
import {
  Chart as ChartJS,
  BarElement,
  LineElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Material } from "../../pages/materials/visualize/utils";
import { useEffect, useState } from "react";
import { getValueFromConcatenatedKey } from "../../utils/materialKeyLabelHelper";
import CustomLoader from "../common/CustomLoader";

ChartJS.register(
  BarElement,
  LineElement, // Register LineElement
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend
);

export interface MaterialBarChartModalProps {
  open: boolean;
  handleOpen: (val: boolean) => void;
  materialProperty: string;
  materials: Material[];
}

const MaterialBarChartModal = ({
  open,
  handleOpen,
  materialProperty,
  materials,
}: MaterialBarChartModalProps) => {
  const [loading, setLoading] = useState(false);
  const [labels, setLabels] = useState<string[]>([]);
  const [dataValues, setDataValues] = useState<number[]>([]);
  const [chartType, setChartType] = useState<'bar' | 'line'>('bar'); // State for chart type

  const yAxisLabel = getValueFromConcatenatedKey(materialProperty);
  const [length,setLength]=useState<number>(0);
  const defaultArray = Array.from({ length }, (_,id) => `Row ${id + 1}`);

  useEffect(() => {
    if(materialProperty === "solid-impurity_and_residuals-chemical_purities"){
      const values = materials.map(
        (material) =>
          (material[materialProperty] ?? []).map((m: any) => m?.value ?? 0)
      );

      const maxLength = Math.max(...values.map((arr) => arr.length));
      setLength(maxLength)
    }
    setLoading(true);
    setLabels(
      materials.map(
        (material) =>
          `Name: ${material.material_name} | Lot Number: ${material.lot_number} | Vendor: ${material?.vendor_name} `
      )
    );
    setDataValues(materials.map((material) => material[materialProperty] ?? 0));
    setLoading(false);
    return () => setChartType("bar");
  }, [materials, materialProperty]);

  const customLabelPlugin = {
    id: "numbersOnBars",
    afterDatasetsDraw: (chart: any) => {
      const { ctx } = chart;
  
      chart.data.datasets.forEach((dataset: any, datasetIndex: number) => {
        const meta = chart.getDatasetMeta(datasetIndex);
  
        if (meta.hidden === null || meta.hidden === false) {
          meta.data.forEach((bar: any, index: number) => {
            const currentUnit = materials[datasetIndex][materialProperty][index]?.unit || "";
            const value = dataset.data[index];
            const label = `${value} ${currentUnit}`;
  
            ctx.save();
            ctx.font = "12px Arial";
            ctx.textAlign = "center";
            ctx.fillStyle = "black";
            ctx.fillText(label, bar.x, bar.y - 5);
            ctx.restore();
          });
        }
      });
    },
  };

  const plugin = {
    id : "custom legend height",
    beforeInit: function (chart:any) {
      const originalFit = chart.legend.fit
  
      chart.legend.fit = function fit() {
        originalFit.bind(chart.legend)()
        this.height += 20 
      }
    }
  }

  const getDataSets = () => {
    if (materialProperty === "solid-impurity_and_residuals-chemical_purities") {
      const values = materials.map(
        (material) =>
          (material[materialProperty] ?? []).map((m: any) => m?.value ?? 0)
      );

      const maxLength = Math.max(...values.map((arr) => arr.length));
      const normalizedValues = values.map(innerArray => {
        return [...innerArray, ...Array(maxLength - innerArray.length).fill(0)];
      });
      return normalizedValues.map((data, index) => ({
        label: labels[index],
        data,
        backgroundColor: index % 2 ? "rgba(238, 75, 43, 0.3)" : "rgba(138, 175, 43, 1)",
        borderColor: index % 2 ? "rgb(238, 75, 43)" : "rgb(138, 175, 43)",
        borderWidth: 1,
      }));

    } 
      return [
        {
          label: yAxisLabel,
          data: dataValues,
          backgroundColor:
            chartType === "bar"
              ? "rgb(238, 75, 43, 0.3)"
              : "rgb(238, 75, 43, 1)", // Change based on chart type
          borderColor: "rgb(238, 75, 43)",
          borderWidth: 1,
        },
      ];
  };

  const data = {
    labels : materialProperty === "solid-impurity_and_residuals-chemical_purities" ? defaultArray  : labels,
    datasets: getDataSets(),
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      }
    },
    scales: {
      y: {
        title: {
          display: true,
          text: yAxisLabel, // Y-axis label
        },
      },
    },
  };

  return (
    <>
      {loading && <CustomLoader />}
      <Modal
        open={open && !loading}
        size="large"
        dimmer="blurring"
        onClose={() => handleOpen(false)}
      >
        <Modal.Header align="center" className="border-none pb-0">
          <Icon
            name="times circle outline"
            onClick={() => {
              handleOpen(false);
            }}
            color="grey"
            className="close-icon"
          />
        </Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
            <Button.Group>
              <Button
                active={chartType === "bar"}
                onClick={() => setChartType("bar")}
              >
                Bar Chart
              </Button>
              {
                materialProperty !== "solid-impurity_and_residuals-chemical_purities" &&
              <Button
              active={chartType === "line"}
              onClick={() => setChartType("line")}
              >
                Line Chart
              </Button>
              }
            </Button.Group>
            {chartType === "bar" ? (
              <Bar
                data={data}
                options={options}
                plugins={
                  materialProperty ===
                  "solid-impurity_and_residuals-chemical_purities"
                    ? [customLabelPlugin,plugin]
                    : []
                }
              />
            ) : (
              <Line data={data} options={options} /> // Render Line chart
            )}
          </Modal.Description>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default MaterialBarChartModal;
