import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";

// components
import { ButtonIcons, ButtonProps, ButtonTypes } from "../../components/common/Button";
import { TableHeadProps, TableHeadType } from "../../components/common/Table/TableHeader";
import { TableRowItemType } from "../../components/common/Table/TableRow";
import BodyHeader from "../../components/layout/BodyHeader";
import CustomLoader from "../../components/common/CustomLoader";
import FilterSidebar from "../../components/material/FilterSidebar";
import Layout from "../../components/layout";
import RenderIf from "../../components/common/RenderIf";
import Table from "../../components/common/Table";
import TableActionButtons from "../../components/common/TableActionButtons";

// modals
import ConfirmCloneModal from "../../components/modals/ConfirmCloneModal";
import ExportFieldsModal from "../../components/modals/ExportFieldsModal";
import HelpModal from "../../components/modals/HelpModal";
import VisualizeMaterialModal from "../../components/modals/VisualizeMaterialModal";

// services
import MaterialService from "../../services/materialService";

// context
import { useUser } from "../../components/context/UserContext";

// redux actions
import { alertCloseAction, alertOpenAction } from "../../redux/actions";
import { materialAction, materialsAction } from "../../redux/actions/materialActions";
import { RootState } from "../../redux/store";

// helpers
import { SortOrder } from "../../types";
import { sortByField } from "../../utils/common";
import { ERROR_MESSAGE, layoutTitles, MATERIAL_SCREENING_PAGE_TITLE } from "../../utils/constant";
import { MaterialListHelp } from "../../utils/helpContent";
import ImportModal from "../../components/modals/ImportModal";

const MaterialList = () => {
  // init
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useUser();

  // auth
  const { authState } = useOktaAuth();
  const auth = authState?.accessToken;
  const hasWritePermission = user?.permissions?.updated?.material?.hasReadAndWrite ?? false;

  const materials = useSelector((state: RootState) => state.material.items) as any[];

  // states
  const [expandedRowIds, setExpandedRowIds] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openExportModal, setOpenExportModal] = useState<boolean>(false);
  const [openVisualizeModal, setOpenVisualizeModal] = useState<boolean>(false);
  const [openHelpModal, setOpenHelpModal] = useState<boolean>(false);
  const [openCloneModal, setOpenCloneModal] = useState<boolean>(false);
  const [isFilterSectionActive, setFilterSectionActive] = useState<boolean>(false);
  const [phase, setPhase] = useState<any>();
  const [cloneId, setCloneId] = useState<any>();
  const [filters, setFilters] = useState<any>({
    gas: {},
    liquid: {},
    phase: "",
    shelf_life: { operation: "", value: "" },
    smiles: "",
    solid: {},
    vendor_name: [],
  });
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [materialList, setMaterialList] = useState<any>([]);
  const [selectedMaterialList, setSelectedMaterialList] = useState<any>([]);
  const [sortBy, setSortBy] = useState<string>("y");
  const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.DESC);
  const isAllMaterialsSelected = selectedRows?.length === materialList?.length;

  // Fetch materials
  const searchMaterials = async () => {
    const payload = {
      uid: `${auth?.claims?.uid}`,
      LastEvaluatedKey: "",
      ...filters,
    };

    setIsLoading(true);
    const res = await MaterialService.getAll(payload);

    setIsLoading(false);
    if (res?.data?.code === 200) {
      if (res?.data?.body?.Items) {
        dispatch(materialsAction(res?.data?.body?.Items ?? []));
      } else {
        dispatch(materialsAction([]));
      }
    } else {
      dispatch(alertOpenAction(ERROR_MESSAGE, "error"));
      setTimeout(() => dispatch(alertCloseAction()));
    }
  };

  //To expand div: show/hide fields
  const toggleExpand = (index: number) => {
    if (expandedRowIds.includes(index)) {
      setExpandedRowIds(expandedRowIds.filter((rowIndex) => rowIndex !== index));
    } else {
      setExpandedRowIds([...expandedRowIds, index]);
    }
  };

  // method to edit material functionality
  const editMaterial = (data: any) => {
    history.push({
      pathname: `/materials/${data?.id}/update`,
      state: { id: `${data?.id}`, page: `list` },
    });
  };

  // method to view material functionality
  const viewMaterial = (data: any) => {
    dispatch(materialAction(data));
    history.push({
      pathname: `/materials/${data?.id}/view`,
      state: { id: `${data?.id}` },
    });
  };

  // method to clone material functionality
  const cloneMaterial = (data: any) => {
    setOpenCloneModal(true);
    setCloneId(data?.id);
  };

  // Handle filter icon button
  const toggleFilterSection = () => {
    setFilterSectionActive(!isFilterSectionActive);
    const filterButton = document.querySelector(".filter-responsive") as HTMLButtonElement;
    if (filterButton && filterButton.classList.contains("alter")) {
      filterButton.classList.remove("alter");
    } else {
      filterButton.classList.add("alter");
    }
  };

  // Handle select all checkbox click
  const handleSelectAll = (e: any) => {
    const checked = e.target.checked;
    setSelectedRows(checked ? materials?.map((row: any) => row.id) : []);
  };

  // Handle row checkbox click
  const handleRowSelect = (material: any) => {
    let newSelectedRows = [];
    let newSelectedMaterials = [];
    if (selectedRows.includes(material.id)) {
      newSelectedRows = selectedRows.filter((id: any) => id !== material.id);
      newSelectedMaterials = selectedMaterialList.filter((m: any) => m.id != material.id);
    } else {
      newSelectedRows = [...selectedRows, material.id];
      newSelectedMaterials = [...selectedMaterialList, material];
    }
    setSelectedMaterialList(newSelectedMaterials);
    setSelectedRows(newSelectedRows);
  };

  // Validate selected rows
  const validateSelectedRows = () => {
    if (selectedMaterialList?.length > 1) {
      if (selectedMaterialList.every((obj: any) => obj["phase"] === selectedMaterialList[0]["phase"])) {
        return true;
      }
      dispatch(alertOpenAction("Please select materials with same phase to visualize.", "error"));
    } else {
      dispatch(alertOpenAction("Please select at-least two materials of same phase to visualize.", "error"));
    }
    setTimeout(() => dispatch(alertCloseAction()));
    return false;
  }

  // Handle Export button click
  const handleExportModal = () => {
    selectedRows?.length ? setOpenExportModal(true) :
      dispatch(alertOpenAction("Please select rows you want to export.", "error"));
    setTimeout(() => dispatch(alertCloseAction()));
  };
  
  // Handle Visualization button click
  const handleVisualizationModal = () => {
    if (validateSelectedRows()) {
      setOpenVisualizeModal(true);
      setPhase(selectedMaterialList?.[0]?.["phase"])
    }
  };

  // Reset selected rows
  const handleReset = () => setSelectedRows([]);

  // Handle sort change
  const manageSort = (newSortBy: string) => {
    let newSortOrder: SortOrder = SortOrder.DESC;
    if (newSortBy == sortBy) {
      newSortOrder = sortOrder == SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
    }
    setSortBy(newSortBy);
    setSortOrder(newSortOrder);
    setMaterialList(sortByField(materialList, newSortBy, newSortOrder));
  }

  // Header Buttons
  const headerButtons: ButtonProps[] = [
    {
      isIconButton: true,
      navigateTo: "#",
      icon: ButtonIcons.FILTER,
      title: "Filter",
      type: ButtonTypes.SECONDARY,
      onClick: toggleFilterSection,
      hideForDesktop: true
    },
    {
      isIconButton: true,
      navigateTo: "#",
      icon: ButtonIcons.VISUALIZE,
      title: "Visualize",
      type: ButtonTypes.PRIMARY,
      onClick: handleVisualizationModal,
    },
    {
      isIconButton: true,
      navigateTo: "#",
      icon: ButtonIcons.EXPORT,
      title: "Export",
      type: ButtonTypes.PRIMARY,
      onClick: handleExportModal,
    },
    {
      isIconButton: true,
      navigateTo: "/materials",
      icon: ButtonIcons.HOME,
      title: "Home",
      type: ButtonTypes.SECONDARY,
    },
    {
      isIconButton: true,
      navigateTo: "#",
      icon: ButtonIcons.HELP,
      title: "Help",
      type: ButtonTypes.SECONDARY,
      onClick: () => setOpenHelpModal(true)
    }
  ]

  // Table Headers
  const tableHeaders: TableHeadProps[] = [
    {
      type: TableHeadType.CHECKBOX,
      onClick: handleSelectAll,
      checked: isAllMaterialsSelected
    },
    {
      type: TableHeadType.EXPAND,
      style: 'w-1'
    },
    {
      title: "Material",
      fieldName: "material_name",
      type: TableHeadType.SORTABLE,
      alignCenter: false,
      sortBy,
      sortOrder,
      manageSort
    },
    {
      title: "Vendor",
      fieldName: "vendor_name",
      type: TableHeadType.SORTABLE,
      alignCenter: false,
      sortBy,
      sortOrder,
      manageSort
    },
    {
      title: "Phase",
      fieldName: "phase",
      type: TableHeadType.SORTABLE,
      alignCenter: false,
      sortBy,
      sortOrder,
      manageSort
    },
    {
      title: "Lot Number",
      fieldName: "lot_number",
      type: TableHeadType.SORTABLE,
      alignCenter: false,
      sortBy,
      sortOrder,
      manageSort
    },
    {
      title: 'Actions',
      style: "w-10"
    }
  ];

  // Get Expanded Row details formatted
  const getExpandedContent = (material: Record<string, any>): TableRowItemType[][] => {
    return [
      [
        { value: "Abbreviation:", style: "f-heavy w-20" },
        { value: material.abbreviation ?? "N/A" },
        { value: "Shelf-life (month):", style: "f-heavy w-20" },
        { value: material?.shelf_life ?? "N/A" },
        { value: "Brand Name:", style: "f-heavy w-20" },
        { value: material.brand_name ?? "N/A" },
      ],
      [
        { value: "Incompatibility:", style: "f-heavy w-20" },
        { value: material.incompatibility ?? "N/A" },
        { value: "Synonyms:", style: "f-heavy w-20" },
        { value: material.synonyms ?? "N/A" },
        { value: "Melting Point (C):", style: "f-heavy w-20" },
        { value: material.melting_point ?? "N/A" },
      ],
      [
        { value: "Process Name:", style: "f-heavy w-20" },
        { value: material.process_name ?? "N/A" },
        { value: "Boiling Point (C):", style: "f-heavy w-20" },
        { value: material.boiling_point ?? "N/A" },
        { value: "Molecular Weight (g/mol):", style: "f-heavy w-20" },
        { value: material.molecular_weight ?? "N/A" },
      ],
      [
        { value: "Manufacturing Lot No:", style: "f-heavy w-20" },
        { value: material.manufacturing_number ?? "N/A" },
        { value: "CAS Number:", style: "f-heavy w-20" },
        { value: material.cas_number ?? "N/A" },
        { value: "Price ($/g):", style: "f-heavy w-20" },
        { value: material.price ?? "N/A" },
      ],
      [
        { value: "Regulatory Filing Data:", style: "f-heavy w-20" },
        { value: material.regulatory_filling_data ?? "N/A" },
        { value: "Chemical Formula:", style: "f-heavy w-20" },
        { value: material.chemical_formula ?? "N/A" },
        { value: "Purity (%)", style: "f-heavy w-20" },
        { value: material.purity ?? "N/A" },
      ],
      [
        { value: "Chemical Safety:", style: "f-heavy w-20" },
        { value: material.chemical_safety ?? "N/A" },
        { value: "SMILES:", style: "f-heavy w-20" },
        { value: material.smiles ?? "N/A" },
        { value: "", style: "" },
        { value: "" },
      ],
    ]
  }

  // Table Details
  const customTableDetails: TableRowItemType[][] = materialList.map((material: any, index: number) => {
    return [
      {
        value: selectedRows.includes(material?.id),
        onClick: () => handleRowSelect(material),
      },
      {
        value: expandedRowIds.includes(index),
        onClick: () => toggleExpand(index),
        customElement: (
          <tr className="child-data">
            <td colSpan={10}>
              <Table style="sm-table" heads={[]} data={getExpandedContent(material)} />
            </td>
          </tr>
        )
      },
      {
        value: material.material_name,
        style: "f-heavy"
      },
      {
        value: material.vendor_name
      },
      {
        value: material.phase
      },
      {
        value: material.lot_number
      },
      {
        value: (
          <TableActionButtons
            hasWritePermission={hasWritePermission}
            onEditClick={() => editMaterial(material)}
            onViewClick={() => viewMaterial(material)}
            onCloneClick={() => cloneMaterial(material)}
          />
        )
      }
    ] as TableRowItemType[];
  });

  // set materials data on fetch complete
  useEffect(() => {
    if (materials && Array.isArray(materials))
      setMaterialList(materials);
  }, [materials]);

  // search materials on filers change
  useEffect(() => {
    if (auth?.claims?.uid && filters) {
      searchMaterials();
    }
  }, [auth?.claims, filters]);

  // show loader when user not authenticated
  if (!auth?.claims) {
    return <CustomLoader />;
  }

  return (
    <Layout title={layoutTitles.materialScreening}>
      {/* Header */}
      <BodyHeader showBackButton onBackClick={() => history.goBack()} title={MATERIAL_SCREENING_PAGE_TITLE} buttons={headerButtons} />

      {/* Body */}
      <div className="theme-card sm filter-card">
        <div className="body p-0">
          <div className="row sm-row row-responsive">
            {/* Filters */}
            <div className={isFilterSectionActive ? "left click-block" : "left"}>
              <FilterSidebar filters={setFilters} user={user} handleReset={handleReset} />
            </div>

            {/* Loading Spinner */}
            <RenderIf condition={isLoading}>
              <CustomLoader />
            </RenderIf>

            {/* Table details */}
            <RenderIf condition={!isLoading}>
              <div className={`right ${materialList?.length ? "" : "full-flex"}`}>

                {/* Empty Table Message */}
                <RenderIf condition={!materialList?.length}>
                  <div>
                    <h1>No data Found !</h1>
                  </div>
                </RenderIf>

                {/* Table */}
                <RenderIf condition={materialList?.length}>
                  <div className="table-outer">
                    <div className="table-responsive theme-table">
                      <Table heads={tableHeaders} data={customTableDetails} />
                    </div>
                  </div>
                </RenderIf>
              </div>
            </RenderIf>
          </div>
        </div>
      </div>

      {/* Modals */}
      <ExportFieldsModal
        open={openExportModal}
        setOpen={setOpenExportModal}
        selectedRows={selectedRows}
        filters={filters}
        selectAllChecked={isAllMaterialsSelected}
      />
      
      <VisualizeMaterialModal
        open={openVisualizeModal}
        setOpen={setOpenVisualizeModal}
        phase={phase}
        selectedRows={selectedRows}
        filters={filters}
        selectAllChecked={isAllMaterialsSelected}
      />
      <ConfirmCloneModal
        open={openCloneModal}
        setOpen={setOpenCloneModal}
        type="Material"
        cloneId={cloneId}
        uid={auth?.claims?.uid ?? ""}
        operation="cloneFromList"
      />
      <HelpModal
        open={openHelpModal}
        setOpen={setOpenHelpModal}
        title={MaterialListHelp.title}
        content={MaterialListHelp.content}
      />
    </Layout >
  );
};

export default MaterialList;
