import { Modal, Icon, Button } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import MultiFileUpload from "../common/MultiFileUpload";
import MaterialService from "../../services/materialService";
import FileService from "../../services/fileService";
import { useOktaAuth } from "@okta/okta-react";
import { ToastContainer, toast } from "react-toastify";
import { set } from "lodash";
import CustomLoader from "../common/CustomLoader";

function capitalizeFirstLetter(str: string) {
  if (!str) return "";
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const ButtonLoaderComponent = ({text}:{text:string}) => {
  return (
    <>
    <i className="fa-solid fa-circle-notch fa-spin"></i>
    {text}
    <div className="theme-loader">
      <div className="loader-outer">
        <div className="loader"></div>
      </div>
    </div>
  </>
  )
}

const ImportModal = ({ open, setOpen , refetch }: any) => {
  const { authState } = useOktaAuth() || {};
  const auth: any = authState ? authState?.accessToken : "";
  const methods = useForm();

  const [loading, setLoading] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const [isValidated, setIsValidated] = useState<boolean>(false);
  const [mainLoading, setMainLoading] = useState<boolean>(false);
  const [convertedData, setConvertedData] = useState<any>([]);
  const [error, setError] = useState<string>("");
  const [validating, setValidating] = useState<boolean>(false);

  const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB limit

  // File Change Handler
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    if (!selectedFile) return;

    if (selectedFile.size > MAX_FILE_SIZE) {
      setError("File size exceeds 5MB limit.");
      setFile(null);
    } else if (
      ![
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "text/csv",
      ].includes(selectedFile.type)
    ) {
      setError("Only Excel (.xlsx) or CSV (.csv) files are allowed.");
      setFile(null);
    } else {
      setError("");
      setFile(selectedFile);
      setIsValidated(false);
    }
  };

  const convertToBase64 = (file: File) => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () =>
        resolve(reader.result?.toString().split(",")[1] || "");
      reader.onerror = (error) => reject(error);
    });
  };

  // Validate Button Handler
  const handleValidate = async (e: any) => {
    e.preventDefault();
    if (!file) return setError("Please upload a file first.");

    setValidating(true);
    setError("");

    try {
      const base64File = await convertToBase64(file);
      const res = await MaterialService.validateMaterial({
        materials: base64File,
      });

      if (res?.data?.code === 200) {
        toast.success(res.data.message,{
          containerId: "modal-toast",
        });
        setConvertedData(res.data.data);
        setIsValidated(true);
      } else {
        const formattedMessage = res.data.message.replace(/\n/g, "<br />");
        console.log(formattedMessage);
  
        toast.error(
          <div dangerouslySetInnerHTML={{ __html: formattedMessage }} />,
          {
            containerId: "modal-toast",
          }
        );
      }
    } catch (err) {
      toast.error("Validation failed.",{
        containerId: "modal-toast",
      });
    } finally {
      setValidating(false);
    }
  };
  // Submit Button Handler
  const handleSubmitFile = async (e: any) => {
    e.preventDefault();
    if (!file || !isValidated) return;

    setLoading(true);
    try {
      const payload = {
        transformedData: convertedData,
        uid: auth?.claims?.uid,
      };
      const response = await MaterialService.importMaterial(payload);
      toast.success(response.data.message,{
        containerId: "modal-toast",
      });
      setFile(null);
      setIsValidated(false);
    } catch (error) {
      setError("Failed to submit the file.");
    } finally {
      setLoading(false);
      closeModal();
      refetch();
    }
  };

  //download template handler
  const handleDownloadTemplate = async (fileKey: string) => {
    setMainLoading(true);
    try {
      const res = await FileService.download({ file: fileKey });
      if (res?.data?.body) {
        const link = document.createElement("a");
        link.href = res.data.body;
        link.download = "Template.xlsx";
        link.click();
      }
    } catch {
      console.error("Error while downloading the template");
    }finally{
      setMainLoading(false);
    }
  };

  const closeModal = () => {
    methods?.reset({});
    setFile(null);
    setError("");
    setOpen(false);
  };

  if(mainLoading){
    return <CustomLoader />
  }

  return (
    <Modal open={open} size="tiny" dimmer="blurring" onClose={closeModal}>
      {/* {
        mainLoading && <CustomLoader />
      } */}
      <FormProvider {...methods}>
        <form>
          {/* Modal Header */}
          <Modal.Header align="center" className="border-none pb-0">
            <p className="head-lg pt-3 pl-4 pb-0 mb-0 pb-1">
              Please Select the file to import!
            </p>
            <Icon
              name="times circle outline"
              onClick={closeModal}
              color="grey"
              className="close-icon"
            />
          </Modal.Header>
          <Modal.Content>
            <div className="m-4">
              <p>
                <strong>Note:</strong> Refer these templates:
                {["solid", "liquid", "gas"].map((type, index) => (
                  <React.Fragment key={type}>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        handleDownloadTemplate(
                          `material-${type}-template.xlsx`
                        );
                      }}
                    >
                      {" "}
                      {capitalizeFirstLetter(type)} Template
                    </a>
                    {index < 2 ? " | " : ""}
                  </React.Fragment>
                ))}
              </p>
            </div>
            <div className="m-4 flex items-center justify-between gap-12">
              <div className="w-2/3">
                <MultiFileUpload
                  title=""
                  name="uploadAttachment"
                  handleFileChange={handleFileChange}
                  removeFile={() => setFile(null)}
                  attachments={file ? [file] : []}
                  acceptFormat=".csv, .xlsx"
                  multiple={false}
                />
              </div>

              <div className="w-1/3">
                <div className="mb-5 flex flex-col gap-4">
                  <button
                    type="submit"
                    className="theme-btn md w-full"
                    onClick={handleValidate}
                    disabled={!file || isValidated}
                  >
                    {validating ? (
                      <ButtonLoaderComponent text="Validating" />
                    ) : isValidated ? (
                      "Verified"
                    ) : (
                      "Validate"
                    )}
                  </button>
                  <button
                    type="submit"
                    className="theme-btn md w-full"
                    onClick={handleSubmitFile}
                    disabled={!isValidated || loading}
                  >
                    {loading ? (
                       <ButtonLoaderComponent text="Importing" />
                    ) : (
                      "Import"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </Modal.Content>

          {/* Modal Actions */}
          <Modal.Actions className="border-none text-center bg-none mb-6 pt-3">
            <Button title="Cancel" size="small" onClick={closeModal}>
              Cancel
            </Button>
          </Modal.Actions>
        </form>
      </FormProvider>
      <ToastContainer
        autoClose={5000}
        containerId="modal-toast"
        position="bottom-center"
      />
    </Modal>
  );
};

export default ImportModal;
